import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';
import { ProductViewModel } from './Product';
import { RecurringInvoiceDetailSalesTaxViewModel } from './RecurringInvoiceDetailSalesTax';

export const RecurringInvoiceDetailValidation = Yup.object().shape({});

export interface RecurringInvoiceDetailViewModel {
  id: number;
  recurringInvoiceID: number;
  productID: number;
  product?: ProductViewModel;
  description: string;
  quantity: number;
  price: number;
  recurringInvoiceDetailSalesTaxes: RecurringInvoiceDetailSalesTaxViewModel[];
  subTotal: number;
}

export interface RecurringInvoiceDetailTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: RecurringInvoiceDetailViewModel[];
}

export interface RecurringInvoiceDetailListResultViewModel
  extends ResultViewModel {
  data: RecurringInvoiceDetailViewModel[];
}

export interface RecurringInvoiceDetailResultViewModel extends ResultViewModel {
  data: RecurringInvoiceDetailViewModel;
}

export const recurringInvoiceDetailDefaultValue: RecurringInvoiceDetailViewModel =
  {
    id: 0,
    recurringInvoiceID: 0,
    productID: 0,
    product: undefined,
    description: '',
    quantity: 0,
    price: 0,
    recurringInvoiceDetailSalesTaxes: [],
    subTotal: 0,
  };

export const recurringInvoiceDetailTableDefaultValue: RecurringInvoiceDetailTableResultViewModel =
  {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    isSuccess: true,
    errorMessage: undefined,
  };
