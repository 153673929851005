import { Menu } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { useAuth } from 'react-oidc-context';

export const UserMenus = () => {
  const auth = useAuth();

  return (
    <div className="pt-1">
      <Menu as="div" className="ml-auto">
        <Menu.Button>
          <UserCircleIcon className="h-8 w-8 text-primary-700" />
        </Menu.Button>
        <Menu.Items className="absolute font-light text-xs text-black right-8 rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            <div className="whitespace-nowrap rounded-md cursor-pointer px-2 py-1">
              Notifications
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="whitespace-nowrap rounded-md cursor-pointer px-2 py-1">
              Mail
            </div>
          </Menu.Item>

          <div className="border-t-2 border-gray-300 my-1" />

          <Menu.Item>
            <div className="whitespace-nowrap rounded-md font-semibold cursor-pointer px-2 py-1">
              {auth.isAuthenticated && (
                <>
                  <div
                    onClick={() => {
                      auth.signoutRedirect();
                    }}
                    className="hover: cursor-pointer"
                  >
                    Logout
                  </div>
                </>
              )}
            </div>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
};
