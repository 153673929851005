import { Formik } from 'formik';
import { useEffect, useState } from 'react';

import { CloseButton } from '@components/ActionButtons/CloseButton';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  FormikInput,
  FormikSelect,
  FormikTextArea,
  SubmitButton,
} from '@components/Form';
import { Space } from '@components/Space';
import { CategoryDefaultValue, CategoryValidation } from '@models/Category';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import { CategoryService } from '@services/Category';

interface Props {
  onClose?: () => void;
}

export const CategoryCreate: React.FC<Props> = ({ onClose }) => {
  const [parentCategories, setParentCategories] = useState<
    FormikSelectOption[]
  >([]);

  const loadParentCategories = () => {
    CategoryService.getParentCategorySelectOptions(0)
      .then((result) => {
        const parentCategorySelections = [{ value: '0', text: '' }].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );
        setParentCategories(parentCategorySelections);
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  useEffect(() => {
    loadParentCategories();
  }, []);

  return (
    <>
      <CloseButton onClick={onClose} />

      <Box size="medium">
        <Formik
          initialValues={CategoryDefaultValue}
          validationSchema={CategoryValidation}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) => {
            if (values.parentId == 0) {
              values.parentId = null;
            }

            CategoryService.create(values)
              .then((response) => {
                if (response.status == 201) {
                  window.location.reload();
                } else {
                  console.log('Error: Failed to create a record.');
                }
              })
              .catch((error) => {
                alert('Error: Failed to handle the request.');
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            return (
              <>
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <FormikInput label="Name" name="name" />

                    <FormikSelect
                      label="Parent"
                      name="parentId"
                      selection={parentCategories}
                    />
                    <FormikTextArea label="Description" name="description" />
                    <Space />
                  </FormSectionContainer>
                  <FormButtonsContainer>
                    <CancelButton onClick={onClose} />

                    <SubmitButton
                      label="Save"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    />
                  </FormButtonsContainer>
                </form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
