import { PlusIcon } from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProductCreate } from '../../Inventory/Product';
import { EstimateAndQuoteCreateCustomerSelectEdit } from '../EstimateAndQuote/EstimateAndQuoteCreateCustomerSelectEdit';

import { Box, BoxColumn, BoxRowColumn } from '@components/Box';
import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import { TrashIcons } from '@components/ButtonSwitchContainer/React-Icon/TrashIcons';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  FormikDateInput,
  FormikSelect,
  FormikTextArea,
  SubmitButton,
} from '@components/Form';
import { CustomInput } from '@components/Form/CustomInput';
import { CustomSelect } from '@components/Form/CustomSelect';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import {
  TableContainer,
  TableDataCenter,
  TableDataLeft,
  TableDataRight,
  TableHeaderCenter,
  TableHeaderLeft,
  TableHeaderRight,
} from '@components/TableContainers';
import { routes } from '@config/routes';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import { ContactResultViewModel } from '@models/Contact';
import { CustomerResultViewModel } from '@models/Customer';
import { ProductResultViewModel } from '@models/Product';
import {
  recurringInvoiceDefaultValue,
  recurringInvoiceValidation,
  paymentDueDateSelectOptions,
  repeatTypeSelectOptions,
  weekdayScheduleSelectionOptions,
  monthdayScheduleSelectOptions,
  monthScheduleSelectOptions,
  recurrenceEndTypeSelectOptions,
  customIntervalTypeSelectOptions,
  timeZoneSelectOptions,
  RecurringInvoiceResultViewModel,
  RecurringInvoiceViewModel,
} from '@models/RecurringInvoice';
import {
  recurringInvoiceDetailDefaultValue,
  RecurringInvoiceDetailViewModel,
} from '@models/RecurringInvoiceDetail';
import {
  recurringInvoiceDetailSalesTaxDefaultValue,
  RecurringInvoiceDetailSalesTaxViewModel,
} from '@models/RecurringInvoiceDetailSalesTax';
import { SalesTaxViewModel } from '@models/SalesTax';
import { ContactService } from '@services/Contact';
import { CustomerService } from '@services/Customer';
import { ProductService } from '@services/Product';
import { RecurringInvoiceService } from '@services/RecurringInvoice';
import { SalesTaxService } from '@services/SalesTax';

export const RecurringInvoiceEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation =
    location.state?.from || routes.RNC_RECURRING_INVOICES;

  const [customerBillingAddresses, setCustomerBillingAddresses] = useState<
    FormikSelectOption[]
  >([]);
  const [customerShippingAddresses, setCustomerShippingAddresses] = useState<
    FormikSelectOption[]
  >([]);

  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [products, setProducts] = useState<FormikSelectOption[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isRecurringInvoiceLoading, setRecurringInvoiceIsLoading] =
    useState(true);
  const [recurringInvoice, setRecurringInvoice] =
    useState<RecurringInvoiceViewModel>(recurringInvoiceDefaultValue);
  const [isSalesTaxEffectiveRatesLoading, setSalesTaxEffectiveRatesIsLoading] =
    useState<boolean>(true);
  const [salesTaxes, setSalesTaxes] = useState<FormikSelectOption[]>([]);
  const [salesTaxEffectiveRates, setSalesTaxEffectiveRates] = useState<
    SalesTaxViewModel[]
  >([]);

  const [isProductsLoading, setProductsIsLoading] = useState(true);

  const [isCustomerContactDetailsLoading, setCustomerContactDetailsIsLoading] =
    useState<boolean>(true);
  const [isCustomerContactsLoading, setCustomerContactsIsLoading] =
    useState<boolean>(true);
  const [
    isCustomerBillingAddressesLoading,
    setCustomerBillingAddressesIsLoading,
  ] = useState<boolean>(true);
  const [
    isCustomerShippingAddressesLoading,
    setCustomerShippingAddressesIsLoading,
  ] = useState<boolean>(true);
  const [
    isRecurringInvoiceDetailsLoading,
    setRecurringInvoiceDetailsIsLoading,
  ] = useState(true);
  const [customerContacts, setCustomerContacts] = useState<
    FormikSelectOption[]
  >([]);

  const [recurringInvoiceDetails, setRecurringInvoiceDetails] = useState([
    {
      ...recurringInvoiceDetailDefaultValue,
      recurringInvoiceDetailSalesTaxes: [
        recurringInvoiceDetailSalesTaxDefaultValue,
      ],
    },
  ]);
  const [repeatType, setRepeatType] = useState<string | null>(null);
  const [customIntervalType, setCustomIntervalType] = useState<string | null>(
    null
  );
  const [recurrenceEndType, setRecurrenceEndType] = useState<string | null>(
    '1'
  );

  const loadRecurringInvoice = () => {
    RecurringInvoiceService.getById(Number(id))
      .then(
        (response) => response.data as Promise<RecurringInvoiceResultViewModel>
      )
      .then((result) => {
        if (result.isSuccess) {
          setRecurringInvoice(result.data);
          setRecurringInvoiceIsLoading(false);
          console.log('Success: Fetched the record.');
        } else {
          console.log(`Error: Failed to get record. ${result.errorMessage}`);
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  const loadRecurringInvoiceCustomerContacts = (customerId: number) => {
    if (customerId === 0) {
      setCustomerContacts([{ value: '0', text: '' }]);
    } else {
      CustomerService.getCustomerContactsSelectOptions(customerId).then(
        (result) => {
          const customerContactsSelectOptions = [
            { value: '0', text: '' },
          ].concat(
            result.data.map((row) => ({
              value: row.value,
              text: row.text,
            }))
          );

          setCustomerContacts(customerContactsSelectOptions);
          setCustomerContactsIsLoading(false);
        }
      );
    }
  };

  const loadRecurringInvoiceCustomerContactDetails = (contactId: number) => {
    if (contactId === 0) {
      setRecurringInvoice({
        ...recurringInvoice,
        contactEmail: '',
        contactPhone: '',
      });
    } else {
      ContactService.getById(contactId)
        .then((response) => response.data as Promise<ContactResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            setRecurringInvoice({
              ...recurringInvoice,
              contactEmail: result.data.email ?? '',
              contactPhone: result.data.phone ?? '',
            });
            setCustomerContactDetailsIsLoading(false);
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch contact details.');
          console.log(error);
        });
    }
  };

  const loadRecurringInvoiceCustomerBillingAddresses = (customerId: number) => {
    if (customerId === 0) {
      setCustomerBillingAddresses([{ value: '0', text: '' }]);
    } else {
      CustomerService.getCustomerBillingAddressesSelectOptions(customerId).then(
        (result) => {
          const customerBillingAddressesSelectOptions = [
            { value: '0', text: '' },
          ].concat(
            result.data.map((row) => ({
              value: row.value,
              text: row.text,
            }))
          );

          setCustomerBillingAddresses(customerBillingAddressesSelectOptions);
          setCustomerBillingAddressesIsLoading(false);
        }
      );
    }
  };

  const loadRecurringInvoiceCustomerShippingAddresses = (
    customerId: number
  ) => {
    if (customerId === 0) {
      setCustomerShippingAddresses([{ value: '0', text: '' }]);
    } else {
      CustomerService.getCustomerShippingAddressesSelectOptions(
        customerId
      ).then((result) => {
        const customerShippingAddressesSelectOptions = [
          { value: '0', text: '' },
        ].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );

        setCustomerShippingAddresses(customerShippingAddressesSelectOptions);
        setCustomerShippingAddressesIsLoading(false);
      });
    }
  };

  const loadRecurringInvoiceDetails = (recurringInvoiceId: number) => {
    if (recurringInvoiceId === 0) {
      setRecurringInvoiceDetails([]);
    } else {
      RecurringInvoiceService.getRecurringInvoiceDetails(Number(id)).then(
        (result) => {
          const recurringInvoiceDetails = result.data.map((row) => ({
            ...row,
            recurringInvoiceDetailSalesTaxes:
              row.recurringInvoiceDetailSalesTaxes.concat([
                recurringInvoiceDetailSalesTaxDefaultValue,
              ]),
          }));
          setRecurringInvoiceDetails(recurringInvoiceDetails);
          setRecurringInvoiceDetailsIsLoading(false);
        }
      );
    }
  };

  const loadSelectedCustomer = (customerId: string, formikProps: any) => {
    if (customerId === '0') {
      formikProps.setFieldValue('contactID', 0);
      formikProps.setFieldValue('contactEmail', '');
      formikProps.setFieldValue('contactPhone', '');
      formikProps.setFieldValue('billingAddressID', 0);
      formikProps.setFieldValue('isSameShipping', false);
      formikProps.setFieldValue('shippingAddressID', 0);
      setCustomerContacts([{ value: '0', text: '' }]);
      setCustomerBillingAddresses([{ value: '0', text: '' }]);
      setCustomerShippingAddresses([{ value: '0', text: '' }]);
    } else {
      CustomerService.getById(Number(customerId))
        .then((response) => response.data as Promise<CustomerResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            formikProps.setFieldValue(
              'contactID',
              result.data.primaryContact?.id || 0
            );
            formikProps.setFieldValue(
              'contactEmail',
              result.data.primaryContact?.email || ''
            );
            formikProps.setFieldValue(
              'contactPhone',
              result.data.primaryContact?.phone || ''
            );
            formikProps.setFieldValue(
              'billingAddressID',
              result.data.billingID || 0
            );
            formikProps.setFieldValue(
              'isSameShipping',
              result.data.isSameShipping
            );
            formikProps.setFieldValue(
              'shippingAddressID',
              result.data.shippingID || 0
            );

            console.log('Fetched Data:', result.data);
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch customer details.');
          console.log(error);
        });

      CustomerService.getCustomerContactsSelectOptions(Number(customerId)).then(
        (result) => {
          const customerContactsSelectOptions = [
            { value: '0', text: '' },
          ].concat(
            result.data.map((row) => ({
              value: row.value,
              text: row.text,
            }))
          );

          setCustomerContacts(customerContactsSelectOptions);
        }
      );

      CustomerService.getCustomerBillingAddressesSelectOptions(
        Number(customerId)
      ).then((result) => {
        const customerBillingAddressesSelectOptions = [
          { value: '0', text: '' },
        ].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );

        setCustomerBillingAddresses(customerBillingAddressesSelectOptions);
      });

      CustomerService.getCustomerShippingAddressesSelectOptions(
        Number(customerId)
      ).then((result) => {
        const customerShippingAddressesSelectOptions = [
          { value: '0', text: '' },
        ].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );

        setCustomerShippingAddresses(customerShippingAddressesSelectOptions);
      });
    }
  };

  const loadSelectedContact = (contactId: string, formikProps: any) => {
    if (contactId === '0') {
      formikProps.setFieldValue('contactEmail', '');
      formikProps.setFieldValue('contactPhone', '');
    } else {
      ContactService.getById(Number(contactId))
        .then((response) => response.data as Promise<ContactResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            formikProps.setFieldValue('contactEmail', result.data.email || '');
            formikProps.setFieldValue('contactPhone', result.data.phone || '');
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch contact details.');
          console.log(error);
        });
    }
  };

  const loadProducts = () => {
    ProductService.getProductSelectOptions()
      .then((result) => {
        const productSelectOptions = [{ value: '0', text: '' }].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );
        setProducts(productSelectOptions);
        setProductsIsLoading(false);
      })
      .catch((error) => {
        alert('Error: Failed to handle the requst.');
        console.log(error);
      });
  };

  const loadEffectiveSalesTaxRates = (effectiveDate: Date) => {
    SalesTaxService.getSalesTaxEffectiveRates(effectiveDate).then((result) => {
      const salesTaxSelectOptions = [{ value: '0', text: '' }].concat(
        result.data.map((row) => ({
          value: row.id.toString(),
          text: row.abbreviation,
        }))
      );
      setSalesTaxes(salesTaxSelectOptions);
      setSalesTaxEffectiveRates(result.data);
      setSalesTaxEffectiveRatesIsLoading(false);
    });
  };

  const loadSelectedProduct = (productId: string, index: number) => {
    const emptyRecurringInvoiceDetail: RecurringInvoiceDetailViewModel =
      recurringInvoiceDetailDefaultValue;
    if (productId === '0') {
      const updatedRecurringInvoiceDetails = [...recurringInvoiceDetails];
      updatedRecurringInvoiceDetails[index] = emptyRecurringInvoiceDetail;
      setRecurringInvoiceDetails(updatedRecurringInvoiceDetails);
    } else {
      ProductService.getById(Number(productId))
        .then((response) => response.data as Promise<ProductResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            const recurringInvoiceDetailSalesTaxes =
              result.data.productSalesTaxes
                .map((o) => {
                  const salesTaxEffectiveRate = salesTaxEffectiveRates.find(
                    (i) => i.id == o.salesTaxId
                  );
                  return {
                    salesTaxID: o.salesTaxId,
                    salesTaxAbbreviation: o.salesTax.abbreviation,
                    rate: salesTaxEffectiveRate
                      ? salesTaxEffectiveRate.rate
                      : 0,
                    salesTaxAmount:
                      (Number(
                        salesTaxEffectiveRate ? salesTaxEffectiveRate.rate : 0
                      ) *
                        Number(result.data.price)) /
                      100,
                  } as RecurringInvoiceDetailSalesTaxViewModel;
                })
                .concat([recurringInvoiceDetailSalesTaxDefaultValue]);

            const newRecurringInvoiceDetail: RecurringInvoiceDetailViewModel = {
              id: 0,
              recurringInvoiceID: 0,
              productID: result.data.id,
              product: result.data,
              description: result.data.description,
              quantity: 1,
              price: result.data.price,
              recurringInvoiceDetailSalesTaxes:
                recurringInvoiceDetailSalesTaxes,
              subTotal: result.data.price,
            };

            const updatedRecurringInvoiceDetails = [...recurringInvoiceDetails];
            updatedRecurringInvoiceDetails[index] = newRecurringInvoiceDetail;
            setRecurringInvoiceDetails(updatedRecurringInvoiceDetails);
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch product details.');
          console.log(error);
        });
    }
  };

  const handleUpdateRecurringInvoiceDetailQuantity = (
    index: number,
    quantity: number
  ) => {
    const updatedRecurringInvoiceDetails = [...recurringInvoiceDetails];
    const updatedRecurringInvoiceDetail = {
      ...updatedRecurringInvoiceDetails[index],
    };
    updatedRecurringInvoiceDetail.quantity = quantity;

    const salesTaxRate =
      updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes.reduce(
        (total, tax) => {
          return tax.salesTaxID === 0 ? total + Number(tax.rate) : total;
        },
        0
      ) || 0;

    const computedSubTotal =
      Number(updatedRecurringInvoiceDetail.price) *
      Number(quantity) *
      (1 + salesTaxRate / 100);

    updatedRecurringInvoiceDetail.subTotal = computedSubTotal;
    updatedRecurringInvoiceDetails[index] = updatedRecurringInvoiceDetail;
    updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes =
      updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes.map(
        (o) => {
          return {
            ...o,
            salesTaxAmount:
              (Number(o.rate) *
                Number(updatedRecurringInvoiceDetail.price) *
                Number(quantity)) /
              100,
          };
        }
      );

    setRecurringInvoiceDetails(updatedRecurringInvoiceDetails);
  };

  const handleUpdateRecurringInvoiceDetailSalesTax = (
    invoiceDetailIndex: number,
    invoiceDetailSalesTaxIndex: number,
    invoiceDetailSalesTaxesLength: number,
    salesTaxID: number
  ) => {
    if (salesTaxID == 0) {
      if (invoiceDetailSalesTaxIndex < invoiceDetailSalesTaxesLength) {
        const updatedRecurringInvoiceDetailSalesTaxes = [
          ...recurringInvoiceDetails[invoiceDetailIndex]
            .recurringInvoiceDetailSalesTaxes,
        ];

        updatedRecurringInvoiceDetailSalesTaxes.splice(
          invoiceDetailSalesTaxIndex,
          1
        );

        const updatedRecurringInvoiceDetails = [...recurringInvoiceDetails];
        const updatedRecurringInvoiceDetail = {
          ...updatedRecurringInvoiceDetails[invoiceDetailIndex],
          recurringInvoiceDetailSalesTaxes:
            updatedRecurringInvoiceDetailSalesTaxes,
        };
        updatedRecurringInvoiceDetails[invoiceDetailIndex] =
          updatedRecurringInvoiceDetail;
        setRecurringInvoiceDetails(updatedRecurringInvoiceDetails);
      }
    } else {
      // Get the sales tax
      const salesTax = salesTaxEffectiveRates.find((o) => o.id == salesTaxID);

      // Get the invoice detail
      const updatedRecurringInvoiceDetails = [...recurringInvoiceDetails];
      const updatedRecurringInvoiceDetail = {
        ...updatedRecurringInvoiceDetails[invoiceDetailIndex],
      };

      // If sales tax already exist, do nothing
      if (
        updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes.findIndex(
          (o) => o.salesTaxID == salesTax?.id
        ) != -1
      ) {
        updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes[
          invoiceDetailSalesTaxIndex
        ] = recurringInvoiceDetailSalesTaxDefaultValue;

        updatedRecurringInvoiceDetails[invoiceDetailIndex] =
          updatedRecurringInvoiceDetail;

        setRecurringInvoiceDetails(updatedRecurringInvoiceDetails);

        return;
      }

      // Compute the sales tax amount
      const updatedRecurringInvoiceDetailSalesTax = {
        ...updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes[
          invoiceDetailSalesTaxIndex
        ],
        salesTaxID: salesTax?.id ?? 0,
        salesTaxAbbreviation: salesTax?.abbreviation ?? '',
        rate: salesTax?.rate ?? 0,
        salesTaxAmount:
          (Number(salesTax?.rate ?? 0) *
            Number(recurringInvoiceDetails[invoiceDetailIndex].price) *
            Number(recurringInvoiceDetails[invoiceDetailIndex].quantity)) /
          100,
      };

      // Update the sales tax
      updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes[
        invoiceDetailSalesTaxIndex
      ] = updatedRecurringInvoiceDetailSalesTax;

      // Add new sales tax line if the last sales tax line is not empty
      if (
        updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes[
          updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes
            .length - 1
        ].salesTaxID > 0
      ) {
        updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes = [
          ...updatedRecurringInvoiceDetail.recurringInvoiceDetailSalesTaxes,
          recurringInvoiceDetailSalesTaxDefaultValue,
        ];
      }

      updatedRecurringInvoiceDetails[invoiceDetailIndex] =
        updatedRecurringInvoiceDetail;

      setRecurringInvoiceDetails(updatedRecurringInvoiceDetails);
    }
  };

  const getSalesTaxesBySalesTaxID = () => {
    const salesTaxes = recurringInvoiceDetails.map((o) =>
      o.recurringInvoiceDetailSalesTaxes
        .filter((o) => o.salesTaxID != 0)
        .map((salesTaxes) => ({ ...salesTaxes, salesTaxRate: salesTaxes.rate }))
    );

    const groupedSalesTaxes = groupBy(
      salesTaxes.flat(),
      'salesTaxAbbreviation'
    ) as RecurringInvoiceDetailSalesTaxViewModel[][];

    return groupedSalesTaxes;
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const validateRecurringInvoiceDetails = () => {
    for (const detail of recurringInvoiceDetails) {
      if (!detail.productID) {
        return false;
      }
    }
    return true;
  };

  const handleAddRecurringInvoiceDetail = () => {
    if (!validateRecurringInvoiceDetails()) {
      alert('Please fill in the fields before adding a new product.');
      return;
    }

    const emptyRecurringInvoiceDetail = {
      ...recurringInvoiceDetailDefaultValue,
      invoiceDetailSalesTaxes: [recurringInvoiceDetailSalesTaxDefaultValue],
    };
    getSalesTaxesBySalesTaxID();
    setRecurringInvoiceDetails((prevRecurringInvoiceDetails) => [
      ...prevRecurringInvoiceDetails,
      emptyRecurringInvoiceDetail,
    ]);
  };

  const handleRemoveRecurringInvoiceDetail = (index: number) => {
    const updatedItems = [...recurringInvoiceDetails];
    updatedItems.splice(index, 1);
    setRecurringInvoiceDetails(updatedItems);
  };

  const handleResetRecurringInvoiceDetail = (index: number) => {
    const updatedItems = [...recurringInvoiceDetails];
    updatedItems[index] = {
      ...recurringInvoiceDetailDefaultValue,
      recurringInvoiceDetailSalesTaxes: [
        recurringInvoiceDetailSalesTaxDefaultValue,
      ],
    };
    setRecurringInvoiceDetails(updatedItems);
  };

  const handleUpdateRecurringInvoiceDetailSalesTaxRates = () => {
    const updatedRecurringInvoiceDetails = recurringInvoiceDetails.map(
      (recurringInvoiceDetail) => {
        return {
          ...recurringInvoiceDetail,
          recurringInvoiceDetailSalesTaxes:
            recurringInvoiceDetail.recurringInvoiceDetailSalesTaxes.map(
              (recurringInvoiceDetailSalesTax) => {
                const salesTax = salesTaxEffectiveRates.find(
                  (i) => i.id == recurringInvoiceDetailSalesTax.salesTaxID
                );
                return {
                  ...recurringInvoiceDetailSalesTax,
                  rate: salesTax?.rate ?? 0,
                  salesTaxAmount:
                    (Number(salesTax?.rate ?? 0) *
                      Number(recurringInvoiceDetail.price) *
                      Number(recurringInvoiceDetail.quantity)) /
                    100,
                };
              }
            ),
        };
      }
    );

    setRecurringInvoiceDetails(updatedRecurringInvoiceDetails);
  };

  useEffect(() => {
    handleUpdateRecurringInvoiceDetailSalesTaxRates();
  }, [salesTaxEffectiveRates]);

  useEffect(() => {
    if (!isRecurringInvoiceLoading) {
      loadRecurringInvoiceCustomerContacts(Number(recurringInvoice.customerID));
      loadRecurringInvoiceCustomerContactDetails(
        Number(recurringInvoice.contactID)
      );
      loadRecurringInvoiceCustomerBillingAddresses(
        Number(recurringInvoice.customerID)
      );
      loadRecurringInvoiceCustomerShippingAddresses(
        Number(recurringInvoice.customerID)
      );
      loadEffectiveSalesTaxRates(new Date());
    }
  }, [isRecurringInvoiceLoading]);

  useEffect(() => {
    if (
      !isSalesTaxEffectiveRatesLoading &&
      !isRecurringInvoiceLoading &&
      !isRecurringInvoiceDetailsLoading &&
      !isCustomerContactDetailsLoading &&
      !isCustomerContactsLoading &&
      !isCustomerBillingAddressesLoading &&
      !isCustomerShippingAddressesLoading &&
      !isProductsLoading
    ) {
      setIsLoading(false);
    }
  }, [
    isSalesTaxEffectiveRatesLoading,
    isRecurringInvoiceLoading,
    isRecurringInvoiceDetailsLoading,
    isCustomerContactDetailsLoading,
    isCustomerContactsLoading,
    isCustomerBillingAddressesLoading,
    isCustomerShippingAddressesLoading,
    isProductsLoading,
  ]);

  useEffect(() => {
    if (id) {
      loadRecurringInvoice();
      loadRecurringInvoiceDetails(Number(id));
      loadProducts();
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Box>
          <div className="pt-2 pb-6">
            <div className="pt-2 pb-6">
              <p className="w-full text-center text-2xl font-bold text-primary-900">
                Edit Recurring Invoice
              </p>
            </div>
          </div>
          <Formik
            initialValues={{
              ...recurringInvoice,
              isSameShipping: recurringInvoice.isSameShipping ?? false,
            }}
            validationSchema={recurringInvoiceValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              console.log(values);

              const formattedValues = {
                ...values,
                date: format(new Date(values.date), 'yyyy-MM-dd'),
                firstInvoiceDate: format(
                  new Date(values.firstInvoiceDate),
                  'yyyy-MM-dd'
                ),
                paymentDueDate: Number(values.paymentDueDate),
                repeatType: Number(values.repeatType),
                recurrenceEndType: Number(values.recurrenceEndType),
              };

              if (formattedValues.repeatType === 1) {
                formattedValues.weekdaySchedule = undefined;
                formattedValues.monthdaySchedule = undefined;
                formattedValues.monthSchedule = undefined;
                formattedValues.customRepeatInterval = undefined;
                formattedValues.customIntervalType = undefined;
              } else if (formattedValues.repeatType === 2) {
                formattedValues.weekdaySchedule = Number(
                  values.weekdaySchedule
                );
                formattedValues.monthdaySchedule = undefined;
                formattedValues.monthSchedule = undefined;
                formattedValues.customRepeatInterval = undefined;
                formattedValues.customIntervalType = undefined;
              } else if (formattedValues.repeatType === 3) {
                formattedValues.weekdaySchedule = undefined;
                formattedValues.monthdaySchedule = Number(
                  values.monthdaySchedule
                );
                formattedValues.monthSchedule = undefined;
                formattedValues.customRepeatInterval = undefined;
                formattedValues.customIntervalType = undefined;
              } else if (formattedValues.repeatType === 4) {
                formattedValues.weekdaySchedule = undefined;
                formattedValues.monthdaySchedule = Number(
                  values.monthdaySchedule
                );
                formattedValues.monthSchedule = Number(values.monthSchedule);
                formattedValues.customRepeatInterval = undefined;
                formattedValues.customIntervalType = undefined;
              } else if (formattedValues.repeatType === 5) {
                formattedValues.weekdaySchedule = undefined;
                formattedValues.monthdaySchedule = undefined;
                formattedValues.monthSchedule = undefined;
                formattedValues.customRepeatInterval = Number(
                  values.customRepeatInterval
                );
                formattedValues.customIntervalType = Number(
                  values.customIntervalType
                );
              }

              if (formattedValues.recurrenceEndType === 2) {
                formattedValues.recurrenceEndOn = format(
                  new Date(values.recurrenceEndOn ?? ''),
                  'yyyy-MM-dd'
                );
              } else {
                formattedValues.recurrenceEndOn = undefined;
              }

              formattedValues.recurringInvoiceDetails = recurringInvoiceDetails;

              RecurringInvoiceService.update(Number(id), formattedValues)
                .then((response) => {
                  if (response.status == 204) {
                    navigate(`${routes.RNC_RECURRING_INVOICES}`);
                  } else {
                    console.log('Error: Failed to update a record.');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              const handleRepeatTypeChange = (
                e: React.ChangeEvent<HTMLSelectElement>
              ) => {
                const selectedValue = e.target.value;
                formikProps.setFieldValue('repeatType', selectedValue);
                setRepeatType(selectedValue);
              };

              const handleCustomIntervalTypeChange = (
                e: React.ChangeEvent<HTMLSelectElement>
              ) => {
                const selectedValue = e.target.value;
                formikProps.setFieldValue('customIntervalType', selectedValue);
                setCustomIntervalType(selectedValue);
              };

              const handleRecurrenceEndTypeChange = (
                e: React.ChangeEvent<HTMLSelectElement>
              ) => {
                const selectedValue = e.target.value;
                formikProps.setFieldValue('recurrenceEndType', selectedValue);
                setRecurrenceEndType(selectedValue);
              };

              useEffect(() => {
                switch (formikProps.values.repeatType) {
                  case 2:
                    setRepeatType('2');
                    break;
                  case 3:
                    setRepeatType('3');
                    break;
                  case 4:
                    setRepeatType('4');
                    break;
                  case 5:
                    setRepeatType('5');
                    break;
                  default:
                    break;
                }
              }, [formikProps.values.repeatType]);

              useEffect(() => {
                switch (formikProps.values.recurrenceEndType) {
                  case 2:
                    setRecurrenceEndType('2');
                    break;
                  case 3:
                    setRecurrenceEndType('3');
                    break;
                  default:
                    break;
                }
              }, [formikProps.values.recurrenceEndType]);

              useEffect(() => {
                if (formikProps.values.recurrenceEndType === 2) {
                  setRecurrenceEndType('2');
                }
              }, [formikProps.values.recurrenceEndType]);

              useEffect(() => {
                if (formikProps.values.recurrenceEndType === 3) {
                  setRecurrenceEndType('3');
                }
              }, [formikProps.values.recurrenceEndType]);

              return (
                <>
                  <form method="POST" onSubmit={formikProps.handleSubmit}>
                    <FormSectionContainer>
                      <BoxRowColumn>
                        <div className="w-[73%]">
                          <BoxColumn>
                            <EstimateAndQuoteCreateCustomerSelectEdit
                              initialSelectedCustomerId={`${recurringInvoice.customerID}`}
                              onCustomerSelectChange={(customerId: string) => {
                                loadSelectedCustomer(customerId, formikProps);
                              }}
                            />

                            <FormikSelect
                              label="Contact Name"
                              name="contactID"
                              selection={customerContacts}
                              onChange={(
                                event: React.ChangeEvent<HTMLSelectElement>
                              ) => {
                                loadSelectedContact(
                                  event.target.value,
                                  formikProps
                                );
                              }}
                            />

                            <BoxRowColumn>
                              <BoxColumn>
                                <FormikInput
                                  label="Email"
                                  name="contactEmail"
                                  readonly={true}
                                />
                              </BoxColumn>

                              <BoxColumn>
                                <FormikInput
                                  label="Phone"
                                  name="contactPhone"
                                  readonly={true}
                                />
                              </BoxColumn>
                            </BoxRowColumn>
                            <BoxColumn>
                              <FormikSelect
                                label="Billing Address"
                                name="billingAddressID"
                                selection={customerBillingAddresses}
                              />

                              {!formikProps.values.isSameShipping ? (
                                <FormikSelect
                                  label="Shipping Address"
                                  name="shippingAddressID"
                                  selection={customerShippingAddresses}
                                />
                              ) : (
                                <>
                                  <label className="block text-sm font-medium text-primary-700">
                                    Shipping Address
                                  </label>
                                  <i>Same as Billing Address</i>
                                </>
                              )}
                            </BoxColumn>

                            <BoxRowColumn>
                              <FormikInput
                                label="Invoice Number"
                                name="recurringInvoiceNumber"
                                readonly={true}
                              />

                              <FormikInput
                                label="PO/SO Number"
                                name="poNumber"
                              />
                            </BoxRowColumn>

                            <BoxRowColumn>
                              <BoxColumn>
                                <FormikDateInput
                                  label="Invoice Date"
                                  name="date"
                                  type="date"
                                  readonly={true}
                                />
                              </BoxColumn>

                              <BoxColumn>
                                <FormikSelect
                                  label="Payment Due"
                                  name="paymentDueDate"
                                  selection={paymentDueDateSelectOptions}
                                />
                              </BoxColumn>
                            </BoxRowColumn>

                            <label className="block text-sm font-medium text-primary-700 py-0">
                              Set Schedule
                            </label>
                            <BoxRowColumn>
                              <BoxColumn>
                                <FormikSelect
                                  label="Repeat this Invoice"
                                  name="repeatType"
                                  selection={repeatTypeSelectOptions}
                                  onChange={handleRepeatTypeChange}
                                  value={formikProps.values.repeatType}
                                />
                              </BoxColumn>

                              {repeatType === '5' && (
                                <BoxColumn>
                                  <FormikInput
                                    label="Every"
                                    name="customRepeatInterval"
                                  />
                                </BoxColumn>
                              )}

                              {repeatType === '2' && (
                                <BoxColumn>
                                  <FormikSelect
                                    label="Every"
                                    name="weekdaySchedule"
                                    selection={weekdayScheduleSelectionOptions}
                                  />
                                </BoxColumn>
                              )}

                              {repeatType === '3' && (
                                <BoxColumn>
                                  <FormikSelect
                                    label="On the"
                                    name="monthdaySchedule"
                                    selection={monthdayScheduleSelectOptions}
                                  />
                                  of every month.
                                </BoxColumn>
                              )}
                            </BoxRowColumn>

                            {repeatType === '4' && (
                              <BoxRowColumn>
                                <BoxColumn>
                                  <FormikSelect
                                    label="Every"
                                    name="monthSchedule"
                                    selection={monthScheduleSelectOptions}
                                  />
                                </BoxColumn>
                                <BoxColumn>
                                  <FormikSelect
                                    label="On the"
                                    name="monthdaySchedule"
                                    selection={monthdayScheduleSelectOptions}
                                  />
                                  day of the month.
                                </BoxColumn>
                              </BoxRowColumn>
                            )}

                            {repeatType === '5' && (
                              <>
                                <BoxRowColumn>
                                  <BoxColumn></BoxColumn>
                                  <BoxColumn>
                                    <FormikSelect
                                      label=""
                                      name="customIntervalType"
                                      selection={
                                        customIntervalTypeSelectOptions
                                      }
                                      onChange={handleCustomIntervalTypeChange}
                                    />
                                  </BoxColumn>
                                </BoxRowColumn>

                                {customIntervalType === '2' && ( //Week
                                  <>
                                    <BoxRowColumn>
                                      <BoxColumn></BoxColumn>
                                      <BoxColumn>
                                        <FormikSelect
                                          label="on"
                                          name="weekdaySchedule"
                                          selection={
                                            weekdayScheduleSelectionOptions
                                          }
                                        />
                                      </BoxColumn>
                                    </BoxRowColumn>
                                  </>
                                )}

                                {customIntervalType === '3' && ( //Month
                                  <>
                                    <BoxRowColumn>
                                      <BoxColumn></BoxColumn>
                                      <BoxColumn>
                                        <FormikSelect
                                          label="on the"
                                          name="monthdaySchedule"
                                          selection={
                                            monthdayScheduleSelectOptions
                                          }
                                        />
                                        day of the month
                                      </BoxColumn>
                                    </BoxRowColumn>
                                  </>
                                )}

                                {customIntervalType === '4' && ( //year
                                  <>
                                    <BoxRowColumn>
                                      <BoxColumn>
                                        <FormikSelect
                                          label="in"
                                          name="monthSchedule"
                                          selection={monthScheduleSelectOptions}
                                        />
                                      </BoxColumn>
                                      <BoxColumn>
                                        <FormikSelect
                                          label="on the"
                                          name="monthdaySchedule"
                                          selection={
                                            monthdayScheduleSelectOptions
                                          }
                                        />
                                        day of the month
                                      </BoxColumn>
                                    </BoxRowColumn>
                                  </>
                                )}
                              </>
                            )}

                            <BoxRowColumn>
                              <BoxColumn>
                                <FormikDateInput
                                  label="Create first invoice on"
                                  name="firstInvoiceDate"
                                />
                              </BoxColumn>
                              <BoxColumn>
                                <FormikSelect
                                  label="And end"
                                  name="recurrenceEndType"
                                  selection={recurrenceEndTypeSelectOptions}
                                  onChange={handleRecurrenceEndTypeChange}
                                />
                              </BoxColumn>
                            </BoxRowColumn>
                            {recurrenceEndType === '1' && (
                              <>
                                <BoxRowColumn>
                                  <BoxColumn></BoxColumn>
                                  <BoxColumn>
                                    <FormikInput
                                      label=""
                                      name="recurrenceEndAfter"
                                    />
                                    invoices
                                  </BoxColumn>
                                </BoxRowColumn>
                              </>
                            )}

                            {recurrenceEndType === '2' && (
                              <>
                                <BoxRowColumn>
                                  <BoxColumn></BoxColumn>
                                  <BoxColumn>
                                    <FormikDateInput
                                      label=""
                                      name="recurrenceEndOn"
                                    />
                                  </BoxColumn>
                                </BoxRowColumn>
                              </>
                            )}

                            <FormikSelect
                              label="Timezone"
                              name="timeZone"
                              selection={timeZoneSelectOptions}
                            />
                            <label className="block text-sm font-medium text-primary-700 py-0">
                              Set a time zone to ensure invoice delivery in the
                              morning based on the recipient{`'`}s time zone.
                            </label>

                            <FormikTextArea label="Notes" name="notes" />
                          </BoxColumn>
                        </div>

                        <BoxColumn>
                          <div>
                            <div className="max-w-5x1 mx-auto">
                              <div className="pt-6">
                                <p className="w-full bg-primary-100 text-sm font-bold text-black py-1.5 text-center">
                                  Details
                                </p>
                              </div>

                              <TableContainer>
                                <div className="bg-[#e7eeeb50]">
                                  <thead>
                                    <tr>
                                      <TableHeaderLeft>
                                        <div className="pl-2">Item Name</div>
                                      </TableHeaderLeft>
                                      <TableHeaderLeft>
                                        Description
                                      </TableHeaderLeft>
                                      <TableHeaderCenter>
                                        Quantity
                                      </TableHeaderCenter>
                                      <TableHeaderCenter>
                                        Price
                                      </TableHeaderCenter>
                                      <TableHeaderCenter>Tax</TableHeaderCenter>
                                      <TableHeaderRight>
                                        SubTotal
                                      </TableHeaderRight>
                                      <TableHeaderRight>
                                        <div className="pr-2"></div>
                                      </TableHeaderRight>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {recurringInvoiceDetails.map(
                                      (
                                        recurringInvoiceDetail,
                                        recurringInvoiceDetailIndex
                                      ) => (
                                        <tr
                                          key={recurringInvoiceDetailIndex}
                                          className={`${
                                            recurringInvoiceDetailIndex % 2 ===
                                            0
                                              ? ''
                                              : 'bg-[#56c39225]'
                                          }`}
                                        >
                                          <TableDataLeft>
                                            <div className="pl-2 flex gap-0">
                                              <CustomSelect
                                                label=""
                                                value={
                                                  recurringInvoiceDetails[
                                                    recurringInvoiceDetailIndex
                                                  ].productID
                                                }
                                                selection={products}
                                                onChange={(
                                                  event: React.ChangeEvent<HTMLSelectElement>
                                                ) => {
                                                  {
                                                    loadSelectedProduct(
                                                      event.target.value,
                                                      recurringInvoiceDetailIndex
                                                    );
                                                  }
                                                }}
                                              />
                                              <button
                                                type="button"
                                                className="mt-1 relative -ml-px inline-flex items-center gap-x-1.5 px-2 py-1 text-sm font-semibold text-gray-900 bg-white border-l border-gray-500 hover:bg-gray-100"
                                                onClick={() =>
                                                  setShowCreateProduct(true)
                                                }
                                              >
                                                <PlusIcon
                                                  width={18}
                                                  height={18}
                                                />
                                              </button>
                                            </div>
                                          </TableDataLeft>
                                          <TableDataLeft>
                                            <CustomInput
                                              readonly={true}
                                              label=""
                                              value={
                                                recurringInvoiceDetails[
                                                  recurringInvoiceDetailIndex
                                                ].description
                                              }
                                            />
                                          </TableDataLeft>
                                          <TableDataCenter>
                                            <CustomInput
                                              label=""
                                              value={
                                                recurringInvoiceDetail.quantity
                                              }
                                              dataType="number"
                                              onChange={(e: any) => {
                                                handleUpdateRecurringInvoiceDetailQuantity(
                                                  recurringInvoiceDetailIndex,
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </TableDataCenter>
                                          <TableDataCenter>
                                            <CustomInput
                                              label=""
                                              value={Number(
                                                recurringInvoiceDetails[
                                                  recurringInvoiceDetailIndex
                                                ].price
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                              dataType="number"
                                              readonly={true}
                                            />
                                          </TableDataCenter>
                                          <div className="min-w-max">
                                            <TableDataCenter>
                                              {recurringInvoiceDetails[
                                                recurringInvoiceDetailIndex
                                              ].recurringInvoiceDetailSalesTaxes.map(
                                                (
                                                  recurringInvoiceDetailSalesTax,
                                                  recurringInvoiceDetailSalesTaxIndex
                                                ) => {
                                                  return (
                                                    <CustomSelect
                                                      key={
                                                        recurringInvoiceDetailSalesTaxIndex
                                                      }
                                                      label=""
                                                      value={
                                                        recurringInvoiceDetails[
                                                          recurringInvoiceDetailIndex
                                                        ]
                                                          .recurringInvoiceDetailSalesTaxes[
                                                          recurringInvoiceDetailSalesTaxIndex
                                                        ].salesTaxID
                                                      }
                                                      selection={
                                                        salesTaxes || []
                                                      }
                                                      onChange={(e: any) => {
                                                        handleUpdateRecurringInvoiceDetailSalesTax(
                                                          recurringInvoiceDetailIndex,
                                                          recurringInvoiceDetailSalesTaxIndex,
                                                          recurringInvoiceDetail
                                                            .recurringInvoiceDetailSalesTaxes
                                                            .length,
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                  );
                                                }
                                              )}
                                            </TableDataCenter>
                                          </div>
                                          <TableDataRight>
                                            <CustomInput
                                              label=""
                                              value={Number(
                                                recurringInvoiceDetails[
                                                  recurringInvoiceDetailIndex
                                                ].subTotal
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                              dataType="number"
                                              readonly={true}
                                            />
                                          </TableDataRight>
                                          <TableDataRight>
                                            <div className="pr-2">
                                              <button
                                                className="py-2"
                                                onClick={() =>
                                                  handleRemoveRecurringInvoiceDetail(
                                                    recurringInvoiceDetailIndex
                                                  )
                                                }
                                              >
                                                <div className="opacity-75 text-primary-500 hover:text-red-800">
                                                  <TrashIcons
                                                    height="24"
                                                    width="24"
                                                  />
                                                </div>
                                              </button>
                                            </div>
                                          </TableDataRight>
                                        </tr>
                                      )
                                    )}

                                    <tr>
                                      <TableDataLeft>
                                        <PrimaryButton
                                          onClick={
                                            handleAddRecurringInvoiceDetail
                                          }
                                        >
                                          Add Item
                                        </PrimaryButton>
                                      </TableDataLeft>
                                    </tr>

                                    <tr>
                                      <td colSpan={7}>
                                        <div className="p-4">
                                          <div className="flex text-sm justify-end">
                                            <div className="font-bold mr-4">
                                              Subtotal:
                                            </div>
                                            <div className="w-24 text-right">
                                              {recurringInvoiceDetails
                                                .reduce(
                                                  (acc, cur) =>
                                                    acc + cur.subTotal,
                                                  0
                                                )
                                                .toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                            </div>
                                          </div>

                                          <Space />

                                          {Object.entries(
                                            getSalesTaxesBySalesTaxID()
                                          ).map(([abbreviation, details]) => (
                                            <div
                                              className="flex text-sm justify-end"
                                              key={abbreviation}
                                            >
                                              <div className="mr-4">
                                                {abbreviation} (
                                                {details[0]?.rate}
                                                %)
                                              </div>
                                              <div className="w-24 text-right">
                                                {details
                                                  .reduce(
                                                    (acc, cur) =>
                                                      acc + cur.salesTaxAmount,
                                                    0
                                                  )
                                                  .toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })}
                                              </div>
                                            </div>
                                          ))}

                                          <div className="flex text-sm justify-end">
                                            <div className="font-bold mr-4">
                                              Total Tax:
                                            </div>
                                            <div className="w-24 text-right">
                                              {recurringInvoiceDetails
                                                .map((o) =>
                                                  o.recurringInvoiceDetailSalesTaxes.reduce(
                                                    (acc, cur) =>
                                                      acc + cur.salesTaxAmount,
                                                    0
                                                  )
                                                )
                                                .reduce(
                                                  (acc, cur) => acc + cur,
                                                  0
                                                )
                                                .toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                            </div>
                                          </div>

                                          <Space />

                                          <div className="flex text-sm justify-end ">
                                            <div className="font-bold mr-4">
                                              Total:
                                            </div>
                                            <div className="w-24 text-right">
                                              {(
                                                recurringInvoiceDetails.reduce(
                                                  (acc, cur) =>
                                                    acc + cur.subTotal,
                                                  0
                                                ) +
                                                recurringInvoiceDetails
                                                  .map((o) =>
                                                    o.recurringInvoiceDetailSalesTaxes.reduce(
                                                      (acc, cur) =>
                                                        acc +
                                                        cur.salesTaxAmount,
                                                      0
                                                    )
                                                  )
                                                  .reduce(
                                                    (acc, cur) => acc + cur,
                                                    0
                                                  )
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </div>
                              </TableContainer>
                            </div>
                          </div>

                          <div className="flex items-end justify-end h-full">
                            <FormButtonsContainer>
                              <CancelButton
                                onClick={() => {
                                  navigate(previousLocation);
                                }}
                              />

                              <SubmitButton
                                label="Save"
                                disabled={
                                  formikProps.isSubmitting ||
                                  !formikProps.isValid
                                }
                              />
                            </FormButtonsContainer>
                          </div>
                        </BoxColumn>
                      </BoxRowColumn>
                    </FormSectionContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>

        {showCreateProduct && (
          <>
            <Modal show={true}>
              <ProductCreate
                onClose={(productResultViewModel) => {
                  setShowCreateProduct(false);

                  if (productResultViewModel && productResultViewModel.data) {
                    const newProducts = [
                      ...products.slice(0, products.length - 1),
                      {
                        value: productResultViewModel.data.id.toString(),
                        text: productResultViewModel.data.name,
                      },
                    ];

                    setProducts(newProducts);

                    loadSelectedProduct(
                      productResultViewModel.data.id.toString(),
                      recurringInvoiceDetails.length - 1
                    );
                  } else {
                    handleResetRecurringInvoiceDetail(
                      recurringInvoiceDetails.length - 1
                    );
                  }
                }}
              />
            </Modal>
          </>
        )}
      </>
    );
  }
};
