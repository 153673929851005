import { useField, FieldHookConfig } from 'formik';
import React from 'react';
import { default as ReactSelect } from 'react-select';

import { ReactSelectOption } from '@models/common/ReactSelectOption';

type Props = {
  label?: string;
  selection: ReactSelectOption[];
  selectedOptions?: ReactSelectOption[];
  changeHandler?: (option: readonly ReactSelectOption[]) => void;
  readonly?: boolean;
  maxMenuHeight?: number;
} & FieldHookConfig<string>;

export const FormikSelectMultiple: React.FC<Props> = ({
  label,
  selection,
  selectedOptions,
  changeHandler,
  maxMenuHeight,
  ...props
}) => {
  const [field, meta] = useField(props);
  field.name = props.name;

  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-green-700">
        {label}
      </label>
      <div className="mt-1">
        <ReactSelect
          isMulti={true}
          options={selection}
          maxMenuHeight={maxMenuHeight}
          isSearchable={true}
          value={selectedOptions}
          onChange={changeHandler}
          placeholder=""
          styles={{
            control: (base, state) => ({
              ...base,
              border: state.isFocused
                ? '2px solid #10B981'
                : '2px solid #6EE7B7',
              backgroundColor: state.isFocused ? '#ECFDF5' : 'none',
              boxShadow: state.isFocused
                ? 'var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) #10B981'
                : 'none',
              '&:hover': {
                border: '2px solid #10B981',
                cursor: 'pointer',
              },
            }),

            multiValueRemove: (base) => ({
              ...base,
              backgroundColor: 'transparent',
              color: '#999',

              '&:hover': { backgroundColor: 'transparent', color: '#000' },
            }),

            menuList: (base) => ({
              ...base,
              color: '#000',
              backgroundColor: 'white',
            }),

            input: (base) => ({
              ...base,
              'input:focus': {
                boxShadow: 'none',
              },
            }),
          }}
        />
        {meta.error ? <div className="text-red-500">{meta.error}</div> : null}
      </div>
    </div>
  );
};
