import { Formik } from 'formik';
import { useContext, useState, useEffect } from 'react';

import { CustomerEditContext } from './CustomerEdit';

import { CloseButton } from '@components/ActionButtons';
import {
  FormSectionContainer,
  FormButtonsContainer,
} from '@components/Container';
import { FormikInput, CancelButton, SubmitButton } from '@components/Form';
import { ActionableSelect } from '@components/Form/ActionableSelect';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import {
  ShippingAddressViewModel,
  shippingAddressDefaultValue,
  shippingAddressValidation,
} from '@models/ShippingAddress';
import { CustomerService } from '@services/Customer';
import { ShippingAddressService } from '@services/ShippingAddress';

export const CustomerEditShippingAddressSelect = () => {
  const { customer, setCustomer } = useContext(CustomerEditContext);
  const [isCustomerShippingsLoading, setIsCustomerShippingsLoading] =
    useState(true);
  const customerShippingAddresses = customer.shippingAddresses || [];
  const [
    customerShippingAddressSelectOptions,
    setCustomerShippingAddressSelectOptions,
  ] = useState<FormikSelectOption[]>([]);
  const [isShowAddShippingAddress, setIsShowAddShippingAddress] =
    useState(false);
  const [isShowEditShippingAddress, setIsShowEditShippingAddress] =
    useState(false);
  const [shippingAddressToEdit, setShippingAddressToEdit] =
    useState<ShippingAddressViewModel | null>();
  const [isLoading, setIsLoading] = useState(true);

  const loadShippingAddresses = () => {
    CustomerService.getCustomerShippingAddresses(Number(customer.id)).then(
      (result) => {
        setCustomer((prevState) => ({
          ...prevState,
          shippingAddresses: result.data,
        }));

        setIsCustomerShippingsLoading(false);
      }
    );
  };

  const loadShippingAddressOptions = () => {
    const customerShippingAddressSelection = [{ value: '0', text: '' }].concat(
      customerShippingAddresses.map((row) => ({
        value: `${row.id}`,
        text: `${row.address1} ${row.address2}, ${row.city}, ${row.state} ${row.postalCode}, ${row.country}`,
      }))
    );
    setCustomerShippingAddressSelectOptions(customerShippingAddressSelection);
  };

  useEffect(() => {
    loadShippingAddressOptions();
  }, [customer.shippingAddresses]);

  useEffect(() => {
    if (!isCustomerShippingsLoading) {
      setIsLoading(false);
    }
  }, [isCustomerShippingsLoading]);

  useEffect(() => {
    loadShippingAddresses();
  }, []);

  console.log('CustomerEditShippingAddressSelect', customer);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <ActionableSelect
          label="Shipping Address"
          value={`${customer.shippingID}`}
          selection={customerShippingAddressSelectOptions}
          onAddAction={() => {
            setIsShowAddShippingAddress(true);
          }}
          onEditAction={() => {
            if (customer.shippingID == undefined || customer.shippingID == 0)
              return;

            const selectedShippingAddress = customerShippingAddresses.find(
              (row) => row.id == customer.shippingID
            );

            if (selectedShippingAddress == undefined) return;

            setShippingAddressToEdit(selectedShippingAddress);
            setIsShowEditShippingAddress(true);
          }}
          onDeleteAction={() => {
            const updatedShippingAddresses = customerShippingAddresses.filter(
              (o) => o.id !== customer.shippingID
            );
            setCustomer({
              ...customer,
              shippingID: 0,
              shippingAddresses: updatedShippingAddresses,
            });
          }}
          onDuplicateAction={() => {
            const selectedShippingAddress = customerShippingAddresses.find(
              (row) => row.id == customer.shippingID
            );

            if (selectedShippingAddress == undefined) return;

            selectedShippingAddress.id = 0;
            ShippingAddressService.create(selectedShippingAddress).then(
              (response) => {
                if (response.status === 201) {
                  const newShippingAddress = {
                    ...selectedShippingAddress,
                    id: response.data.data.id,
                  };

                  const updatedShippingAddresses =
                    customerShippingAddresses.concat(newShippingAddress);

                  setCustomer({
                    ...customer,
                    shippingAddresses: updatedShippingAddresses,
                  });

                  setShippingAddressToEdit(newShippingAddress);
                  setIsCustomerShippingsLoading(true);
                }
              }
            );
          }}
          onChange={(e) => {
            const updateCustomer = {
              ...customer,
              shippingId: Number(e.target.value),
            };
            setCustomer(updateCustomer);
          }}
        />

        {isShowAddShippingAddress && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setIsShowAddShippingAddress(false)} />

              <Formik
                initialValues={shippingAddressDefaultValue}
                validationSchema={shippingAddressValidation}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  values.customerId = Number(customer.id);

                  ShippingAddressService.create(values).then((response) => {
                    if (response.status === 201) {
                      values.id = response.data.data.id;
                      const updatedShippingAddresses =
                        customerShippingAddresses.concat(values);

                      setCustomer({
                        ...customer,
                        shippingID: values.id,
                        shippingAddresses: updatedShippingAddresses,
                      });

                      actions.resetForm();
                      setIsShowAddShippingAddress(false);
                    }
                  });
                }}
              >
                {(formikProps) => {
                  return (
                    <>
                      <form method="POST" onSubmit={formikProps.handleSubmit}>
                        <FormSectionContainer>
                          <FormikInput
                            label="Currency Code"
                            name="currencyCode"
                          />
                          <FormikInput label="Address 1" name="address1" />
                          <FormikInput label="Address 2" name="address2" />
                          <FormikInput label="City" name="city" />
                          <FormikInput label="State / Province" name="state" />
                          <FormikInput label="Country" name="country" />
                          <FormikInput label="Postal Code" name="postalCode" />
                        </FormSectionContainer>
                        <FormButtonsContainer>
                          <CancelButton
                            onClick={() => {
                              setIsShowAddShippingAddress(false);
                              formikProps.resetForm();
                            }}
                          />

                          <SubmitButton
                            label="Save"
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          />
                        </FormButtonsContainer>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}

        {isShowEditShippingAddress && shippingAddressToEdit && (
          <>
            <Modal show={true}>
              <CloseButton
                onClick={() => setIsShowEditShippingAddress(false)}
              />

              <Formik
                initialValues={shippingAddressToEdit}
                validationSchema={shippingAddressValidation}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  ShippingAddressService.update(values.id, values).then(
                    (response) => {
                      if (response.status === 204) {
                        const updatedShippingAddresses =
                          customerShippingAddresses.map((row) =>
                            row.id === values.id ? values : row
                          );

                        setCustomer({
                          ...customer,
                          shippingID: values.id,
                          shippingAddresses: updatedShippingAddresses,
                        });

                        actions.resetForm();
                        setIsShowEditShippingAddress(false);
                      }
                    }
                  );
                }}
              >
                {(formikProps) => {
                  return (
                    <>
                      <form method="POST" onSubmit={formikProps.handleSubmit}>
                        <FormSectionContainer>
                          <FormikInput
                            label="Currency Code"
                            name="currencyCode"
                          />
                          <FormikInput label="Address 1" name="address1" />
                          <FormikInput label="Address 2" name="address2" />
                          <FormikInput label="City" name="city" />
                          <FormikInput label="State / Province" name="state" />
                          <FormikInput label="Country" name="country" />
                          <FormikInput label="Postal Code" name="postalCode" />
                        </FormSectionContainer>
                        <FormButtonsContainer>
                          <CancelButton
                            onClick={() => {
                              setIsShowEditShippingAddress(false);
                              formikProps.resetForm();
                            }}
                          />

                          <SubmitButton
                            label="Save"
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          />
                        </FormButtonsContainer>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}
      </>
    );
  }
};
