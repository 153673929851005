import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';

export const TransactionsList = () => {
  return (
    <>
      <GridComponent
        allowPaging={true}
        pageSettings={{ pageSize: 10 }}
        allowFiltering={true}
      >
        <ColumnsDirective>
          <ColumnDirective field="" headerText="Transaction" />
          <ColumnDirective field="" headerText="Details" />
          <ColumnDirective field="" headerText="Contact" />
          <ColumnDirective field="" headerText="Action" />
        </ColumnsDirective>
        <Inject services={[Page, Toolbar]} />
      </GridComponent>
    </>
  );
};
