interface Props {
  children?: React.ReactNode;
}

export const TableHeaderLeft: React.FC<Props> = ({ children }) => {
  return (
    <th
      scope="col"
      className="pt-3.5 text-left px-1 text-xs font-semibold text-primary-700"
    >
      {children}
    </th>
  );
};
