import { Formik } from 'formik';
import { ChangeEvent, createContext, useEffect, useState } from 'react';

import { EstimateAndQuoteCustomerCreateBillingAddressSelectEdit } from './EstimateAndQuoteCustomerCreateBillingAddressSelectEdit';
import { EstimateAndQuoteCustomerCreateContactSelectEdit } from './EstimateAndQuoteCustomerCreateContactSelectEdit';
import { EstimateAndQuoteCustomerCreateShippingAddressSelectEdit } from './EstimateAndQuoteCustomerCreateShippingAddressSelectEdit';

import { CloseButton } from '@components/ActionButtons';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  FormikCheckbox,
  FormikInput,
  FormikTextArea,
  SubmitButton,
} from '@components/Form';
import { ActionableFormikSelect } from '@components/Form/ActionableFormikSelect';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import {
  CustomerValidation,
  CustomerViewModel,
  customerDefaultValue,
} from '@models/Customer';
import { CustomerService } from '@services/Customer';

interface Props {
  initialSelectedCustomerId: string;
  onCustomerSelectChange?: (customerId: string) => void;
}

interface ICustomerCreateContextModel {
  customer: CustomerViewModel;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerViewModel>>;
}

export const CustomerCreateContext = createContext<ICustomerCreateContextModel>(
  {
    customer: customerDefaultValue,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCustomer: () => {},
  }
);

export const EstimateAndQuoteCreateCustomerSelectEdit: React.FC<Props> = ({
  initialSelectedCustomerId,
  onCustomerSelectChange,
}) => {
  const [customer, setCustomer] =
    useState<CustomerViewModel>(customerDefaultValue);

  const [customerSelectOptions, setCustomerSelectOptions] = useState<
    FormikSelectOption[]
  >([]);
  const [isShowAddCustomer, setIsShowAddCustomer] = useState(false);

  const [selectedCustomerId, setSelectedCustomerId] = useState(
    initialSelectedCustomerId
  );

  const loadCustomerOptions = () => {
    CustomerService.getCustomerSelectOptions().then((result) => {
      const customerSelections = [{ value: '0', text: '' }].concat(
        result.data.map((row) => ({
          value: row.value,
          text: row.text,
        }))
      );

      setCustomerSelectOptions(customerSelections);
    });
  };

  useEffect(() => {
    loadCustomerOptions();
  }, [selectedCustomerId]);

  return (
    <>
      <ActionableFormikSelect
        label="Customer"
        name="customerID"
        value={selectedCustomerId}
        selection={customerSelectOptions}
        onAddAction={() => {
          setIsShowAddCustomer(true);
        }}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          const customerId = e.target.value;
          if (onCustomerSelectChange) {
            onCustomerSelectChange(customerId);
          }
          setSelectedCustomerId(customerId);
        }}
      />

      {isShowAddCustomer && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setIsShowAddCustomer(false)} />

            <CustomerCreateContext.Provider value={{ customer, setCustomer }}>
              <Formik
                initialValues={customerDefaultValue}
                validationSchema={CustomerValidation}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(values: CustomerViewModel, actions) => {
                  values.primaryContact = undefined;
                  values.defaultBillingAddress = undefined;
                  values.defaultShippingAddress = undefined;

                  if (!!customer.contacts && customer.contacts.length > 0) {
                    if (
                      customer.contactUiid != undefined &&
                      customer.contactUiid != 0
                    ) {
                      const primaryContact = customer.contacts.find(
                        (row) => row.uiid == parseInt(`${customer.contactUiid}`)
                      );

                      values.contactID = primaryContact?.uiid;
                      values.primaryContact = primaryContact;

                      values.contacts = customer.contacts.filter(
                        (o) => o.uiid !== parseInt(`${customer.contactUiid}`)
                      );
                    } else {
                      values.contacts = customer.contacts;
                    }
                  }

                  if (
                    !!customer.billingAddresses &&
                    customer.billingAddresses.length > 0
                  ) {
                    if (
                      customer.billingUiid != undefined &&
                      customer.billingUiid != 0
                    ) {
                      const defaultBillingAddress =
                        customer.billingAddresses.find(
                          (row) =>
                            row.uiid == parseInt(`${customer.billingUiid}`)
                        );

                      values.billingID = defaultBillingAddress?.uiid;
                      values.defaultBillingAddress = defaultBillingAddress;

                      values.billingAddresses =
                        customer.billingAddresses.filter(
                          (o) => o.uiid !== parseInt(`${customer.billingUiid}`)
                        );
                    } else {
                      values.billingAddresses = customer.billingAddresses;
                    }
                  }

                  if (
                    !!customer.shippingAddresses &&
                    customer.shippingAddresses.length > 0
                  ) {
                    if (
                      customer.shippingUiid != undefined &&
                      customer.shippingUiid != 0
                    ) {
                      const defaultShippingAddress =
                        customer.shippingAddresses.find(
                          (row) =>
                            row.uiid == parseInt(`${customer.shippingUiid}`)
                        );

                      values.shippingID = defaultShippingAddress?.uiid;
                      values.defaultShippingAddress = defaultShippingAddress;

                      values.shippingAddresses =
                        customer.shippingAddresses.filter(
                          (o) => o.uiid !== parseInt(`${customer.shippingUiid}`)
                        );
                    } else {
                      values.shippingAddresses = customer.shippingAddresses;
                    }
                  }

                  CustomerService.create(values)
                    .then((response) => {
                      if (response.status === 201) {
                        const newCustomerID = response.data.data.id.toString();

                        setSelectedCustomerId(newCustomerID);

                        if (onCustomerSelectChange) {
                          onCustomerSelectChange(newCustomerID);
                        }
                      } else {
                        console.log('Error: Failed to create a record.');
                      }
                    })
                    .catch((error) => {
                      alert('Error: Failed to handle the request.');
                      console.log(error);
                    })
                    .finally(() => {
                      actions.setSubmitting(false);
                    });

                  actions.resetForm();
                  setIsShowAddCustomer(false);
                }}
              >
                {(formikProps) => {
                  return (
                    <>
                      <form method="POST" onSubmit={formikProps.handleSubmit}>
                        <FormSectionContainer>
                          <FormikInput
                            label="Name / Company Name"
                            name="companyName"
                          />
                          <FormikInput
                            label="Account Number"
                            name="accountNumber"
                          />
                          <FormikInput label="Website" name="website" />
                          <FormikTextArea label="Notes" name="notes" />

                          <EstimateAndQuoteCustomerCreateContactSelectEdit />

                          <EstimateAndQuoteCustomerCreateBillingAddressSelectEdit />

                          <FormikCheckbox
                            label="Same as Billing Address"
                            name="isSameShipping"
                          />

                          {!formikProps.values.isSameShipping && (
                            <EstimateAndQuoteCustomerCreateShippingAddressSelectEdit />
                          )}

                          <Space />
                        </FormSectionContainer>
                        <FormButtonsContainer>
                          <CancelButton
                            onClick={() => {
                              setIsShowAddCustomer(false);
                              formikProps.resetForm();
                            }}
                          />

                          <SubmitButton
                            label="Save"
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          />
                        </FormButtonsContainer>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </CustomerCreateContext.Provider>
          </Modal>
        </>
      )}
    </>
  );
};
