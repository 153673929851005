import { useField, FieldHookConfig } from 'formik';
import React, { CSSProperties } from 'react';
import { default as ReactSelect } from 'react-select';

import {
  ReactSelectGroupedOption,
  ReactSelectOption,
} from '@models/common/ReactSelectOption';

type Props = {
  label?: string;
  selection: ReactSelectGroupedOption[];
  selectedOption?: ReactSelectOption;
  changeHandler?: (option: ReactSelectOption) => void;
  readonly?: boolean;
  maxMenuHeight?: number;
} & FieldHookConfig<string>;

export const FormikSelectGrouped: React.FC<Props> = ({
  label,
  selection,
  selectedOption,
  changeHandler,
  maxMenuHeight,
  ...props
}) => {
  const [field, meta] = useField(props);
  field.name = props.name;

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles: CSSProperties = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = (data: ReactSelectGroupedOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-green-700">
        {label}
      </label>
      <div className="mt-1">
        <ReactSelect
          isMulti={false}
          options={selection}
          maxMenuHeight={maxMenuHeight}
          isSearchable={false}
          value={selectedOption}
          onChange={(option) => {
            if (!option) {
              return;
            }
            field.onChange({
              target: {
                name: field.name,
                value: option.value,
              },
            });
            if (changeHandler) {
              changeHandler(option);
            }
          }}
          placeholder=""
          formatGroupLabel={formatGroupLabel}
        />
        {meta.error ? <div className="text-red-500">{meta.error}</div> : null}
      </div>
    </div>
  );
};
