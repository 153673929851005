import { Outlet } from 'react-router-dom';

import { PageContent } from '@components/Page';

export const Accounting = () => {
  return (
    <>
      {/* <PageHeading></PageHeading> */}

      <PageContent>
        <Outlet />
      </PageContent>
    </>
  );
};
