import { HandleClicker } from './HandleClicker';

import { AccountingIcons } from '@components/ButtonSwitchContainer/React-Icon/AccountingIcons';
import { DashBoardIcons } from '@components/ButtonSwitchContainer/React-Icon/DashBoard';
import { InventoryIcon } from '@components/ButtonSwitchContainer/React-Icon/InventoryIcon';
import { ReportsIcons } from '@components/ButtonSwitchContainer/React-Icon/ReportIcons';
import { RevenueIcons } from '@components/ButtonSwitchContainer/React-Icon/RevenueIcons';
import { SettingsIcon } from '@components/ButtonSwitchContainer/React-Icon/SettingsIcon';
import { SidebarMenu } from '@components/ButtonSwitchContainer/React-Icon/SideBarIcons/SidebarMenu';
import { routes } from '@config/routes';

interface Props {
  onClose: () => void;
}

export const SideBarResponsive: React.FC<Props> = ({ onClose }) => {
  return (
    <>
      <div className="grid gap-y-10">
        <HandleClicker to={`${routes.HOME}`}>
          <DashBoardIcons height="25" width="25" />
        </HandleClicker>

        <SidebarMenu
          subMenuActions={[
            {
              title: 'Estimate and Quotes',
              to: `${routes.RNC_ESTIMATES}`,
              onClick: () => onClose(),
            },
            {
              title: 'Recurring Invoices',
              to: `${routes.RNC_RECURRING_INVOICES}`,
              onClick: () => onClose(),
            },
            {
              title: 'Invoices',
              to: `${routes.RNC_INVOICES}`,
              onClick: () => onClose(),
            },
            {
              title: 'Customers',
              to: `${routes.RNC_CUSTOMERS}`,
              onClick: () => onClose(),
            },
          ]}
        >
          <RevenueIcons width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu
          subMenuActions={[
            {
              title: 'Category',
              to: `${routes.CATEGORIES}`,
              onClick: () => onClose(),
            },
            {
              title: 'Products and Services',
              to: `${routes.INVENTORY_PRODUCTS}`,
              onClick: () => onClose(),
            },
          ]}
        >
          <InventoryIcon width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu
          subMenuActions={[
            {
              title: 'Charts of Accounts',
              to: `${routes.CHARTOFACCOUNTS}`,
              onClick: () => onClose(),
            },
          ]}
        >
          <AccountingIcons width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu>
          <ReportsIcons width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu
          subMenuActions={[
            {
              title: 'Sales Tax',
              to: `${routes.SALES_TAX}`,
              onClick: () => onClose(),
            },
          ]}
        >
          <SettingsIcon width="25" height="25" />
        </SidebarMenu>
      </div>
    </>
  );
};
