import axios from 'axios';

import { api } from '@config/apiEndpoints';
import {
  SalesTaxListResultViewModel,
  SalesTaxTableResultViewModel,
  SalesTaxViewModel,
} from '@models/SalesTax';
import { SalesTaxRateListResultViewModel } from '@models/SalesTaxRate';
import { getDataUrl } from '@utils/dataUrls';

export const SalesTaxService = {
  create: async function (tax: SalesTaxViewModel) {
    const createUrl = `${api.BASE_URL}${api.SALESTAXES_ENDPOINT}`;

    return axios.post(createUrl, tax);
  },
  getList: async function (
    page?: number,
    pageSize?: number,
    searchFields?: string,
    search?: string,
    filters?: string
  ) {
    let dataUrl = getDataUrl(
      api.BASE_URL,
      api.SALESTAXES_ENDPOINT,
      page,
      pageSize,
      searchFields,
      search
    );
    if (filters) {
      dataUrl = dataUrl + filters;
    }

    return axios.get(dataUrl).then((response) => {
      return response.data as Promise<SalesTaxTableResultViewModel>;
    });
  },
  getById: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.SALESTAXES_ENDPOINT}/${id}`;

    return axios.get(dataUrl);
  },

  getSalesTaxRates: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.SALESTAXES_ENDPOINT}/${id}/sales-tax-rates`;

    return axios
      .get(dataUrl)
      .then(
        (response) => response.data as Promise<SalesTaxRateListResultViewModel>
      );
  },

  getSalesTaxEffectiveRates: async function (effectiveDate: Date) {
    const dataUrl = `${api.BASE_URL}${
      api.SALESTAXES_ENDPOINT
    }/effective-rates?effectiveDate=${effectiveDate.toISOString()}`;

    return axios
      .get(dataUrl)
      .then(
        (response) => response.data as Promise<SalesTaxListResultViewModel>
      );
  },

  update: async function (id: number, tax: SalesTaxViewModel) {
    const updateUrl = `${api.BASE_URL}${api.SALESTAXES_ENDPOINT}/${id}`;

    return axios.put(updateUrl, tax);
  },
  delete: async function (id: number) {
    const deleteURL = `${api.BASE_URL}${api.SALESTAXES_ENDPOINT}/${id}`;

    return axios.delete(deleteURL);
  },
};
