import { Formik } from 'formik';
import { useEffect, useState } from 'react';

import { CloseButton } from '@components/ActionButtons/CloseButton';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  FormikCheckbox,
  FormikSelectMultiple,
  SubmitButton,
} from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { Space } from '@components/Space';
import { ReactSelectOption } from '@models/common/ReactSelectOption';
import {
  ProductDefaultValue,
  ProductResultViewModel,
  productValidation,
} from '@models/Product';
import { salesTaxDefaultValue } from '@models/SalesTax';
import { ProductService } from '@services/Product';
import { SalesTaxService } from '@services/SalesTax';

const initialSalesTaxes: ReactSelectOption[] = [];

interface Props {
  onClose?: (productResultViewModel?: ProductResultViewModel) => void;
}

export const ProductCreate: React.FC<Props> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSalesTaxLoading, setIsSalesTaxLoading] = useState(true);

  const [salesTaxSelectOptions, setSalesTaxSelectOptions] =
    useState<ReactSelectOption[]>(initialSalesTaxes);

  const [productSalesTaxes, setProductSalesTaxes] =
    useState<ReactSelectOption[]>();

  const handleProductSalesTaxesOnChange = (
    option: readonly ReactSelectOption[]
  ) => {
    const selectedSalesTaxOptions = option.map((row) => ({
      value: row.value,
      label: row.label,
    }));
    setProductSalesTaxes(selectedSalesTaxOptions);
  };

  const loadSalesTaxes = () => {
    SalesTaxService.getList()
      .then((result) => {
        const selectOptions = result.data.map((row) => ({
          value: row.id.toString(),
          label: row.abbreviation,
        }));

        setSalesTaxSelectOptions(selectOptions);

        setIsSalesTaxLoading(false);
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  useEffect(() => {
    loadSalesTaxes();
  }, []);

  useEffect(() => {
    if (!isSalesTaxLoading) {
      setIsLoading(false);
    }
  }, [isSalesTaxLoading]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <CloseButton onClick={() => onClose && onClose()} />

        <Box size="medium">
          <Formik
            initialValues={ProductDefaultValue}
            validationSchema={productValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              if (productSalesTaxes) {
                values.productSalesTaxes = productSalesTaxes.map((row) => ({
                  id: Number(row.value),
                  productId: 0,
                  salesTaxId: parseInt(row.value.toString()),
                  salesTax: salesTaxDefaultValue,
                }));
              }

              ProductService.create(values)
                .then((response) => {
                  if (response.status == 201) {
                    if (onClose) {
                      const product = response.data as ProductResultViewModel;
                      onClose(product);
                    }
                  } else {
                    console.log('Error: Failed to create a record.');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <>
                  <form method="POST" onSubmit={formikProps.handleSubmit}>
                    <FormSectionContainer>
                      <FormikInput label="Name" name="name" />
                      <FormikInput label="Description" name="description" />
                      <FormikInput label="Price" name="price" />
                      <div className="flex">
                        <FormikCheckbox label="Sell This" name="isSales" />
                        <FormikCheckbox label="Buy This" name="isActive" />
                      </div>

                      <FormikSelectMultiple
                        label="Sales Tax"
                        name="productSalesTaxes"
                        selection={salesTaxSelectOptions}
                        changeHandler={handleProductSalesTaxesOnChange}
                        maxMenuHeight={100}
                      />

                      <Space />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <CancelButton onClick={() => onClose && onClose()} />

                      <SubmitButton
                        label="Save"
                        disabled={
                          formikProps.isSubmitting || !formikProps.isValid
                        }
                      />
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
