import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';

import { Box } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { routes } from '@config/routes';

export const Bills = () => {
  return (
    <Box>
      <BoxBodyContainer>
        <BreadCrumbs
          links={[
            { link: 'Purchase and Payments', to: `` },
            { link: 'Bills', to: `${routes.BILLS}` },
          ]}
        />
        <div className="grid justify-items-center pt-4">
          <h2 className="sr-only">Steps</h2>
          {/* Steps code here */}
          <div className="pt-4 relative after:absolute after:inset-x-0 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-100">
            <ol className="relative space-x-52 z-10 flex justify-between text-sm font-medium text-gray-500">
              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full border-green-400 bg-green-600 border  text-center text-[10px]/6 font-bold text-white">
                  1
                </span>
              </li>

              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full bg-green-600 border-green-400  border text-center text-[10px]/6 font-bold text-white">
                  2
                </span>
              </li>

              <li className="flex items-center gap-2 bg-white pb-4">
                <span className="h-6 w-6 rounded-full border-green-600 border text-center text-[10px]/6 font-bold text-black">
                  3
                </span>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <h1 className="text-3xl pt-8 text-center text-green-700">
            Bill Details
          </h1>
        </div>
      </BoxBodyContainer>
      <BoxBodyContainer>
        <Outlet />
      </BoxBodyContainer>
    </Box>
  );
};
