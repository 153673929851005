import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';

export interface PaymentViewModel {
  id: number;
  invoiceId?: number;
  date: string;
  amount: number;
  method?: string;
  account?: number;
  notes?: string;
  isActive?: true;
}

export interface PaymentResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: PaymentViewModel;
}

export interface PaymentListResultViewModel extends ResultViewModel {
  data: PaymentViewModel[];
}

export interface PaymentTableViewModel extends TableViewModel {
  data: PaymentViewModel[];
}

export const paymentDefaultValue: PaymentViewModel = {
  id: 0,
  invoiceId: 0,
  date: '',
  amount: 0,
  method: '',
  account: 0,
  notes: '',
  isActive: true,
};

export const paymentTableDefaultValue: PaymentTableViewModel = {
  data: [],
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
};

export const PaymentValidation = Yup.object().shape({
  date: Yup.date().required('Date field is required'),
  notes: Yup.string()
    .max(200, 'Notes exceeds the maximum length of 200 characters')
    .nullable(),
});

export const paymentMethodSelectOptions = [
  {
    value: '1',
    text: 'CASH',
  },
  {
    value: '2',
    text: 'CARD',
  },
  {
    value: '3',
    text: 'CHECK',
  },
  {
    value: '4',
    text: 'OTHERS',
  },
];
