import { Menu } from '@headlessui/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface ActionProps {
  title: string;
  to: string;
  onClick: () => void;
}

interface Props {
  children: React.ReactNode;
  subMenuActions?: ActionProps[];
}

export const SidebarMenu: React.FC<Props> = ({ subMenuActions, children }) => {
  return (
    <>
      <div className="my-auto">
        <Menu as="div" className="ml-auto">
          <Menu.Button>{children}</Menu.Button>
          <Menu.Items className="absolute gap-4 font-light text-xs text-black ml-11 -mt-8 rounded-lg bg-white p-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none ">
            {subMenuActions?.map((act, index) => (
              <>
                <Menu.Item key={index}>
                  <div className="whitespace-nowrap rounded-md cursor-pointer pl-3 pr-5 py-2">
                    <Link to={act.to} onClick={act.onClick}>
                      {act.title}
                    </Link>
                  </div>
                </Menu.Item>
              </>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
};
