import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { SelectOption } from './common/SelectOption';
import { TableViewModel } from './common/TableViewModel';

export const CategoryValidation = Yup.object().shape({
  name: Yup.string()
    .max(100, 'Name exceeds the maximum length of 100 characters')
    .required('Name is a required field'),
  description: Yup.string()
    .max(1000, 'Description exceeds the maximum length of 200 characters')
    .nullable(),
});

export interface CategoryViewModel {
  id: number;
  name: string;
  parentId?: number | null;
  parentName?: string;
  description?: string;
  active: boolean;
}

// Category Result View Models
export interface CategoryTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: CategoryViewModel[];
}

export interface CategoryResultViewModel extends ResultViewModel {
  data: CategoryViewModel;
}

export interface CategorySelectOptionResultViewModel extends ResultViewModel {
  data: SelectOption[];
}

// Category Model Default Initializer
export const CategoryDefaultValue: CategoryViewModel = {
  id: 0,
  name: '',
  parentId: null,
  description: '',
  active: true,
};

export const categoryTableDefaultValue: CategoryTableResultViewModel = {
  data: [],
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
  isSuccess: true,
  errorMessage: undefined,
};
