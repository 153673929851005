import { Formik } from 'formik';
import { useEffect, useState } from 'react';

import { CloseButton } from '@components/ActionButtons/CloseButton';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  DeleteButton,
  FormikTextArea,
  SubmitButton,
} from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { FormikSelectGrouped } from '@components/Form/FormikSelectGrouped';
import { Loading } from '@components/Loading';
import { Space } from '@components/Space';
import {
  ChartOfAccountDefaultValue,
  ChartOfAccountResultViewModel,
  ChartOfAccountValidation,
} from '@models/ChartOfAccount';
import {
  ReactSelectGroupedOption,
  ReactSelectOption,
} from '@models/common/ReactSelectOption';
import { ChartOfAccountService } from '@services/ChartofAccount';

interface Props {
  chartOfAccountId?: number;
  onDelete?: () => void;
  onClose?: () => void;
}

export const ChartOfAccountEdit: React.FC<Props> = ({
  chartOfAccountId,
  onClose,
  onDelete,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chartOfAccount, setChartOfAccount] = useState(
    ChartOfAccountDefaultValue
  );
  const [accountTypes, setAccountTypes] = useState<ReactSelectGroupedOption[]>(
    []
  );
  const [selectedAccountType, setSelectedAccountType] =
    useState<ReactSelectOption>();

  const loadChartOfAccount = () => {
    ChartOfAccountService.getById(Number(chartOfAccountId))
      .then((respone) => respone.data as Promise<ChartOfAccountResultViewModel>)
      .then((result) => {
        if (result.isSuccess) {
          setSelectedAccountType({
            value: result.data.type,
            label: result.data.type,
          });
          setChartOfAccount(result.data);
          setIsLoading(false);
          console.log('Success: Fetched the record.');
        } else {
          console.log('Error: Failed to get the record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  const loadChartOfAccountTypes = () => {
    ChartOfAccountService.getChartOfAccountTypes().then((result) => {
      const assets = result.data
        .filter((row) => row.typeGroup === 'Assets')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const liabilitiesAndCreditCards = result.data
        .filter((row) => row.typeGroup === 'LiabilitiesAndCreditCards')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const income = result.data
        .filter((row) => row.typeGroup === 'Income')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const expenses = result.data
        .filter((row) => row.typeGroup === 'Expenses')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const equity = result.data
        .filter((row) => row.typeGroup === 'Equity')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const accountTypeSelections: ReactSelectGroupedOption[] = [
        {
          label: 'ASSETS',
          options: assets,
        },
        {
          label: 'LIABILITIES AND CREDIT CARDS',
          options: liabilitiesAndCreditCards,
        },
        {
          label: 'INCOME',
          options: income,
        },
        {
          label: 'EXPENSES',
          options: expenses,
        },
        {
          label: 'EQUITY',
          options: equity,
        },
      ];

      setAccountTypes(accountTypeSelections);
    });
  };

  useEffect(() => {
    loadChartOfAccount();
    loadChartOfAccountTypes();
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <CloseButton onClick={onClose} />

        <Box size="medium">
          <Formik
            initialValues={chartOfAccount}
            validationSchema={ChartOfAccountValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              ChartOfAccountService.update(Number(chartOfAccountId), values)
                .then((response) => {
                  if (response.status == 204) {
                    window.location.reload();
                  } else {
                    console.log('Error: Failed to update record.');
                    alert('Error: Failed to update record.');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <>
                  <form method="POST" onSubmit={formikProps.handleSubmit}>
                    <FormSectionContainer>
                      <FormikSelectGrouped
                        label="Account Type"
                        name="type"
                        selection={accountTypes}
                        selectedOption={selectedAccountType}
                      />
                      <FormikInput label="Account Name" name="name" />
                      <FormikInput label="Account ID" name="account" />
                      <FormikTextArea label="Description" name="description" />
                      <FormikInput label="Account Currency" name="currency" />
                      <Space />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <div className="flex space-x-4">
                        <CancelButton onClick={onClose} />

                        <DeleteButton onClick={onDelete} />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </div>
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
