interface Props {
  label: string;
}

export const DataHeader: React.FC<Props> = ({ label }) => {
  return (
    <>
      <div className="font-bold text-medium text-gray-400">{label}</div>
    </>
  );
};
