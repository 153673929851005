import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelButton, FormikSelect, SubmitButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Space } from '@components/Space';
import { ContactValidation, contactDefaultValue } from '@models/Contact';
import { ContactService } from '@services/Contact';
import { routes } from 'src/config/routes';

interface Props {
  onClose?: () => void;
}

export const ContactCreate: React.FC<Props> = ({ onClose }) => {
  const navigate = useNavigate();

  return (
    <>
      <CloseButton onClick={onClose} />

      <Box size="medium">
        <Formik
          initialValues={contactDefaultValue}
          validationSchema={ContactValidation}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) => {
            ContactService.create(values)
              .then((response) => {
                if (response.status == 201) {
                  navigate(`${routes.CONTACTS}`);
                } else {
                  console.log('Error: Failed to create a record.');
                }
              })
              .catch((error) => {
                alert('Error: Failed to handle the request.');
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            return (
              <>
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <FormikInput label="Firstname" name="firstname" />
                    <FormikInput label="Lastname" name="lastname" />
                    <FormikInput label="Email" name="email" />
                    <FormikInput label="Mobile" name="mobile" />
                    <FormikInput label="Phone" name="phone" />
                    <FormikSelect
                      label="Customer"
                      name="customerId"
                      selection={customers}
                    />
                    <Space />
                  </FormSectionContainer>
                  <FormButtonsContainer>
                    <CancelButton onClick={onClose} />

                    <SubmitButton
                      label="Save"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    />
                  </FormButtonsContainer>
                </form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
