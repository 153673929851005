import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

import { PageContainer } from '@components/PageContainer';
import { routes } from '@config/routes';

export const ProductsAndServicesonPurchase = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        links={[
          { link: 'Purchase and Payments', to: `` },
          { link: 'Products and Services', to: `${routes.PURCHASE_PRODUCTS}` },
        ]}
      />
      {/* change this to main content */}
      <HeaderContainer>This is Products and Services</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
