import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelLinkButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { routes } from '@config/routes';
import { ContactViewModel, contactDefaultValue } from '@models/Contact';
import { ContactService } from '@services/Contact';

export const ContactDetails: React.FC = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setcontact] = useState(contactDefaultValue);
  const navigate = useNavigate();

  const loadData = () => {
    ContactService.getById(Number(id))
      .then((response) => response.json() as Promise<ContactViewModel>)
      .then((result) => {
        if (result.id) {
          setcontact(result);
          setIsLoading(false);
          console.log('Success: Fetch the record.');
        } else {
          console.log('Error: Failed to get record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle thr request');
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const handleNameClick = (contactId: number) => {
    navigate(`${routes.CONTACTS}/${contactId}/details`);
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <Box size="medium">
          <Formik
            initialValues={contact}
            validateOnBlur={true}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
          >
            {() => {
              return (
                <>
                  <form>
                    <FormSectionContainer>
                      <FormikInput
                        label="Firstname"
                        name="firstname"
                        onClick={() => handleNameClick(contact.id)}
                      />
                      <FormikInput label="Lastname" name="lastname" />
                      <FormikInput label="Email" name="email" />
                      <FormikInput label="Mobile" name="mobile" />
                      <FormikInput label="Phone" name="phone" />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <CancelLinkButton to={`${routes.CONTACTS}`} />
                      <button
                        className="bg-[#58993C] w-20 ml-4 rounded-md "
                        onClick={() =>
                          navigate(`${routes.CONTACTS}/${contact.id}/edit`)
                        }
                      >
                        Edit
                      </button>
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
