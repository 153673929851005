import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface ActionProps {
  title: string;
  onClick: () => void;
  showTopSeparator?: boolean;
}

interface Props {
  dropDownActions?: ActionProps[];
  deleteAction?: ActionProps[];
}

export const MoreAction: React.FC<Props> = ({
  dropDownActions,
  deleteAction,
}) => {
  return (
    <>
      <div className="my-auto">
        <Menu as="div" className="ml-auto">
          <Menu.Button className="flex items-center space-x-2 rounded-md border-2 border-primary-500 text-primary-500 px-4 py-1 hover:text-primary-700">
            <span>More Actions</span>
            <ChevronDownIcon width={20} height={20} />
          </Menu.Button>
          <Menu.Items className="absolute mt-0.5 w-40 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {dropDownActions?.map((act, index) => (
              <>
                {act.showTopSeparator && (
                  <div
                    key={index}
                    className="border-t-2 border-gray-300 my-2"
                  />
                )}

                <Menu.Item key={index}>
                  <div
                    onClick={act.onClick}
                    className="hover:bg-primary-500 rounded-md cursor-pointer pl-2"
                  >
                    {act.title}
                  </div>
                </Menu.Item>
              </>
            ))}
            {deleteAction?.map((del, index) => (
              <>
                <div key={index} className="border-t-2 border-gray-300 my-2" />

                <Menu.Item key={index}>
                  <div
                    onClick={del.onClick}
                    className="hover:bg-primary-500 text-red-500 hover:text-black rounded-md cursor-pointer pl-2"
                  >
                    {del.title}
                  </div>
                </Menu.Item>
              </>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
};
