interface Props {
  children?: React.ReactNode;
  onClick: () => void;
}

export const PrimaryButton: React.FC<Props> = ({ children, onClick }) => {
  return (
    <>
      <div className="flex items-center">
        <button
          type="button"
          className=" bg-primary-500 text-white rounded-md px-4 hover:bg-primary-700 py-1.5"
          onClick={onClick}
        >
          <span className="text-500 font-normal">{children}</span>
        </button>
      </div>
    </>
  );
};
