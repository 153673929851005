import axios from 'axios';

import {
  CurrencyTableResultViewModel,
  CurrencyViewModel,
} from '@models/Currency';
import { getDataUrl } from '@utils/dataUrls';
import { api } from 'src/config/apiEndpoints';

export const CurrencyService = {
  create: async function (currency: CurrencyViewModel) {
    const createUrl = `${api.BASE_URL}${api.CURRENCIES_ENDPOINT}`;

    return axios.post(createUrl, currency);
  },
  getList: async function (
    page?: number,
    pageSize?: number,
    searchFields?: string,
    search?: string,
    filters?: string
  ) {
    let dataUrl = getDataUrl(
      api.BASE_URL,
      api.CURRENCIES_ENDPOINT,
      page,
      pageSize,
      searchFields,
      search
    );

    if (filters) {
      dataUrl = dataUrl + filters;
    }

    return axios.get(dataUrl).then((response) => {
      return response.data as Promise<CurrencyTableResultViewModel>;
    });
  },

  getById: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CURRENCIES_ENDPOINT}/${id}`;

    return axios.get(dataUrl);
  },
  update: async function (id: number, currency: CurrencyViewModel) {
    const updateUrl = `${api.BASE_URL}${api.CURRENCIES_ENDPOINT}/${id}`;

    return axios.put(updateUrl, currency);
  },
  delete: async function (id: number) {
    const deleteURL = `${api.BASE_URL}${api.CURRENCIES_ENDPOINT}/${id}`;

    return axios.delete(deleteURL);
  },
};
