import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CloseButton, DeleteButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelButton, SubmitButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { Space } from '@components/Space';
import {
  ContactListResultViewModel,
  ContactValidation,
  ContactViewModel,
} from '@models/Contact';
import { ContactService } from '@services/Contact';

interface Props {
  contactId?: number;
  onClose?: () => void;
  onDelete?: () => void;
}

export const ContactEdit: React.FC<Props> = ({
  contactId,
  onClose,
  onDelete,
}) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState<ContactViewModel[]>([]);

  const loadData = () => {
    ContactService.getById(Number(contactId))
      .then((response) => response.data as Promise<ContactListResultViewModel>)
      .then((result) => {
        if (result.isSuccess) {
          setContact(result.data);
          setIsLoading(false);
          console.log('Success: Fetched the record.');
        } else {
          console.log('Error: Failed to get record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [id]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <CloseButton onClick={onClose} />

        <Box size="medium">
          <Formik
            initialValues={contact}
            validationSchema={ContactValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              ContactService.update(Number(contactId), values)
                .then((response) => {
                  if (response.status == 204) {
                    window.location.reload();
                  } else {
                    console.log('Error: Failed to create a record.');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <>
                  <form method="POST" onSubmit={formikProps.handleSubmit}>
                    <FormSectionContainer>
                      <FormikInput label="Firstname" name="firstname" />
                      <FormikInput label="Lastname" name="lastname" />
                      <FormikInput label="Email" name="email" />
                      <FormikInput label="Phone" name="phone" />
                      <FormikInput label="CustomerID" name="customerId" />
                      <Space />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <div className="flex space-x-4">
                        <CancelButton onClick={onClose} />

                        <DeleteButton onClick={onDelete} />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </div>
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
