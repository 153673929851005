import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { ChartOfAccountCreate } from '.';

import { AddRecord } from '@components/ActionButtons';
import { BoxBodyContainer, BoxHeaderTitle } from '@components/Box';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { Modal } from '@components/Modal';
import { routes } from '@config/routes';

export const ChartOfAccount: React.FC = () => {
  const [showCreateAccount, SetShowCreateAccount] = useState(false);

  const baseBreadCrumbs = [{ link: 'Accounting', to: '' }];

  const isOnChartOfAccountsPage = location.pathname === routes.CHARTOFACCOUNTS;

  const breadCrumbLinks = isOnChartOfAccountsPage
    ? baseBreadCrumbs
    : [
        ...baseBreadCrumbs,
        { link: 'Chart of Accounts', to: routes.CHARTOFACCOUNTS },
      ];

  return (
    <>
      <div className="p-4 space-y-4">
        <div className="flex justify-between items-center">
          <div className="text-sm font-semibold text-gray-500">
            Revenue and Collections
          </div>
          <div>
            <BreadCrumbs links={breadCrumbLinks} />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center">
            <BoxHeaderTitle>
              <GridHeaderMenuTitle>
                <div className="text-2xl font-black">Chart of Accounts</div>
              </GridHeaderMenuTitle>
            </BoxHeaderTitle>

            <div className="">
              <div className="flex flex-row space-x-1">
                <AddRecord
                  label="Add New Account"
                  onClick={() => SetShowCreateAccount(true)}
                />
              </div>
              {showCreateAccount && (
                <>
                  <Modal show={true}>
                    <ChartOfAccountCreate
                      onClose={() => SetShowCreateAccount(false)}
                    />
                  </Modal>
                </>
              )}
            </div>
          </div>

          <div className="p-4 bg-white rounded-b-xl rounded-tr-xl">
            <BoxBodyContainer>
              <Outlet />
            </BoxBodyContainer>
          </div>
        </div>
      </div>
    </>
  );
};
