import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface DataPoint {
  name: string;
  uv: number;
  pv: number;
}

interface MyBarChartProps {
  data: DataPoint[];
}

const MyBarChart: React.FC<MyBarChartProps> = ({ data }) => {
  return (
    <div className=" bg-white  text-xs">
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" fill="#59CB9A" />
          <Bar dataKey="uv" fill="#F9C053" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MyBarChart;
