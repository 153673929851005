import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { Box, BoxBodyContainer } from '@components/Box';
import { routes } from '@config/routes';

export const BankConnections = () => {
  return (
    <Box>
      <BoxBodyContainer>
        <BreadCrumbs
          links={[
            {
              link: 'Accounting',
              to: ``,
            },
            {
              link: 'Back Connections',
              to: `${routes.BANK_CONNECTIONS}`,
            },
          ]}
        />
      </BoxBodyContainer>
      <Outlet />
    </Box>
  );
};
