import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';

export const RecurringInvoiceDetailValidation = Yup.object().shape({});

export interface RecurringInvoiceDetailSalesTaxViewModel {
  id: number;
  recurringInvoiceDetailID: number;
  salesTaxID: number;
  salesTaxAbbreviation: string;
  salesTaxAmount: number;
  rate: number;
}

export interface RecurringInvoiceDetailSalesTaxTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: RecurringInvoiceDetailSalesTaxViewModel[];
}

export interface RecurringInvoiceDetailSalesTaxResultViewModel
  extends ResultViewModel {
  data: RecurringInvoiceDetailSalesTaxViewModel;
}

export const recurringInvoiceDetailSalesTaxDefaultValue: RecurringInvoiceDetailSalesTaxViewModel =
  {
    id: 0,
    recurringInvoiceDetailID: 0,
    salesTaxID: 0,
    salesTaxAbbreviation: '',
    salesTaxAmount: 0,
    rate: 0,
  };

export const recurringInvoiceDetailSalesTaxTableDefaultValue: RecurringInvoiceDetailSalesTaxTableResultViewModel =
  {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    isSuccess: true,
    errorMessage: undefined,
  };
