import React from 'react';
import { PieChart, Pie, Tooltip } from 'recharts';

// Define the type for the data
interface PieData {
  name: string;
  value: number;
}

// Sample data
const data01: PieData[] = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const CustomPieChart: React.FC = () => {
  return (
    <PieChart width={100} height={100}>
      <Pie
        data={data01}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={50}
        fill="#53CC96"
      />

      <Tooltip />
    </PieChart>
  );
};

export default CustomPieChart;
