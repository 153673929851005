import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { ProductCreate } from './ProductCreate';

import { AddRecord } from '@components/ActionButtons';
import { BoxHeaderTitle } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { Modal } from '@components/Modal';
import { routes } from '@config/routes';

export const ProductandServices = () => {
  const [showCreateProduct, setShowCreateProduct] = useState(false);

  const location = useLocation();

  const baseBreadCrumbs = [{ link: 'Inventory', to: '' }];

  const isOnProductsPage = location.pathname === routes.INVENTORY_PRODUCTS;

  const breadCrumbLinks = isOnProductsPage
    ? baseBreadCrumbs
    : [
        ...baseBreadCrumbs,
        { link: 'Products and Services', to: routes.INVENTORY_PRODUCTS },
      ];

  return (
    <>
      <div className="p-4 space-y-4">
        <div className="flex justify-between items-center">
          <div className="text-sm font-semibold text-gray-500">Inventory</div>
          <div>
            <BreadCrumbs links={breadCrumbLinks} />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center">
            <BoxHeaderTitle>
              <GridHeaderMenuTitle>
                <div className="text-2xl font-black">Products and Services</div>
              </GridHeaderMenuTitle>
            </BoxHeaderTitle>

            <div className="">
              <div className="flex flex-row space-x-1">
                <AddRecord
                  label="New Product and Services"
                  onClick={() => setShowCreateProduct(true)}
                />
              </div>
            </div>
          </div>

          <div className="p-4 bg-white rounded-b-xl rounded-tr-xl">
            <BoxBodyContainer>
              <Outlet />
            </BoxBodyContainer>
          </div>
        </div>
      </div>

      {showCreateProduct && (
        <>
          <Modal show={true}>
            <ProductCreate
              onClose={() => {
                setShowCreateProduct(false);
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
};
