import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';

export const InvoiceDetailValidation = Yup.object().shape({});

export interface InvoiceDetailSalesTaxViewModel {
  id: number;
  invoiceDetailID: number;
  salesTaxID: number;
  salesTaxAbbreviation: string;
  salesTaxAmount: number;
  rate: number;
}

export interface InvoiceDetailSalesTaxTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: InvoiceDetailSalesTaxViewModel[];
}

export interface InvoiceDetailSalesTaxResultViewModel extends ResultViewModel {
  data: InvoiceDetailSalesTaxViewModel;
}

export const invoiceDetailSalesTaxDefaultValue: InvoiceDetailSalesTaxViewModel =
  {
    id: 0,
    invoiceDetailID: 0,
    salesTaxID: 0,
    salesTaxAbbreviation: '',
    salesTaxAmount: 0,
    rate: 0,
  };

export const invoiceDetailSalesTaxTableDefaultValue: InvoiceDetailSalesTaxTableResultViewModel =
  {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    isSuccess: true,
    errorMessage: undefined,
  };
