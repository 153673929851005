interface Props {
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilter?: () => void;
  onClear?: () => void;
  label?: string;
}

export const FilterRecord: React.FC<Props> = ({
  value,
  onChange,
  onClear,
  onFilter,
  label,
}) => {
  return (
    <>
      <div className="w-full">
        <div className="grid justify-start">
          <label className="block text-sm font-medium text-green-700">
            {label}
          </label>
          <div className="mt-1 flex space-x-4">
            <input
              type="text"
              value={value}
              onChange={(e) => onChange && onChange(e)}
              placeholder={label}
              className="w-full rounded-lg border-green-400- flex-initial appearance-none border border-green-300  py-2 pl-4 pr-10 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
            />
            <button
              onClick={onFilter}
              className="bg-primary-500 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700"
            >
              Filter
            </button>
            <button
              onClick={onClear}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
