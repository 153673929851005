interface Props {
  children?: React.ReactNode;
}

export const ButtonCLick: React.FC<Props> = ({ children }) => {
  return (
    <button className="text-md items-center rounded-full  mr-4 ">
      {children}
    </button>
  );
};
