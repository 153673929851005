import tw from 'tailwind-styled-components';

interface BaseInputProps {
  hasError: boolean;
}

const BaseInput = tw.input<BaseInputProps>`
block w-full rounded-md shadow-sm bg-white sm:text-sm
${(props) =>
  !props.hasError &&
  !props.value &&
  'border-green-300 focus:border-green-500 focus:ring-green-500'} 
${(props) =>
  !props.hasError &&
  !!props.value &&
  'border-green-300 focus:border-green-500 focus:ring-green-500 bg-green-100'} 
${(props) =>
  props.hasError &&
  !props.value &&
  'border-green-300 focus:border-green-500 focus:ring-green-500'} 
${(props) =>
  props.hasError &&
  !!props.value &&
  'border-red-300 focus:border-red-500 focus:ring-red-500 bg-red-100'} 
`;

type Props = {
  label?: string;
  readonly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  error?: string;
  name: string;
};

export const CsvFileInput: React.FC<Props> = ({
  label,
  readonly,
  onChange,
  value,
  error,
  name,
}) => {
  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-green-700">
        {label}
      </label>
      <div className="mt-1">
        <BaseInput
          name={name}
          onChange={onChange}
          readOnly={readonly}
          type="file"
          value={value}
          hasError={!!error}
        />
        {error ? <div className="text-red-500">{error}</div> : null}
      </div>
    </div>
  );
};
