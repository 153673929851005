import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';

export const ReconList = () => {
  return (
    <GridComponent
      allowPaging={true}
      pageSettings={{ pageSize: 10 }}
      allowFiltering={true}
    >
      <ColumnsDirective>
        <ColumnDirective field="" headerText="Name" />
        <ColumnDirective field="" headerText="Description" />
        <ColumnDirective field="" headerText="Action" />
      </ColumnsDirective>
      <Inject services={[Page, Toolbar]} />
    </GridComponent>
  );
};
