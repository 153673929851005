import { Link } from 'react-router-dom';

import { HomeIcon } from '@components/ButtonSwitchContainer/React-Icon/HomeIcon';

interface BreadCrumbLink {
  link: string;
  to: string;
}

interface Props {
  links: BreadCrumbLink[];
}

export const BreadCrumbs: React.FC<Props> = ({ links }) => {
  return (
    <nav className="flex pt-[8px]" aria-label="Breadcrumb">
      <ol role="list" className="mx-4 flex w-full max-w-screen-xl space-x-2 ">
        <li key={`homelink`} className="flex">
          <div className="flex items-center">
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <HomeIcon height="4" width="4" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>

        {links.map((link, index) => {
          return (
            <>
              <li key={`${index}arrow`} className="flex ">
                <div className="flex items-center">
                  <svg
                    className="h-2 w-2 flex-shrink-0 text-gray-200 stroke-width-2.5"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="black"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                </div>
              </li>

              <li key={`${index}link`} className="flex">
                <div className="flex items-center">
                  <Link
                    className="text-[#000000]  block transition hover:text-green-600 text-sm"
                    to={link.to}
                  >
                    {link.link}
                  </Link>
                </div>
              </li>
            </>
          );
        })}
      </ol>
    </nav>
  );
};
