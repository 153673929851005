import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';

export interface CurrencyViewModel {
  id: number;
  name: string;
  shortName: string;
  symbol: string;
}

export interface CurrencyTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: CurrencyViewModel[];
}

export interface CurrencyResultViewModel extends ResultViewModel {
  data: CurrencyViewModel;
}

export const currencyDefaultValue: CurrencyViewModel = {
  id: 0,
  name: '',
  shortName: '',
  symbol: '',
};

export const currencyTableDefaultValue: CurrencyTableResultViewModel = {
  data: [],
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
  isSuccess: true,
  errorMessage: undefined,
};

export const CurrencyValidation = Yup.object().shape({
  name: Yup.string()
    .max(100, 'Name exceeds the maximum length of 100 characters')
    .required('Name is Required'),
  shortName: Yup.string()
    .max(1000, 'short name exceeds the maximum length of 10 characters')
    .nullable(),
  symbol: Yup.string()
    .max(1000, 'symbol exceeds the maximum length of 10 characters')
    .required('Symbol is Required'),
});
