import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';
import { SalesTaxRateViewModel } from './SalesTaxRate';

export const salesTaxValidation = Yup.object().shape({
  name: Yup.string()
    .max(20, 'Name exceeds the maximum lenght of 20 character')
    .required('Name is Required'),
  description: Yup.string()
    .max(200, 'Description exceeds tha maximum lenght of 200 character')
    .nullable(),
  shortname: Yup.string()
    .max(20, 'Abbreviation exceeds the maximum lenght of 20 character')
    .nullable(),
});

export interface SalesTaxViewModel {
  id: number;
  name: string;
  description: string;
  abbreviation: string;
  rate: number;
  salesTaxRates?: SalesTaxRateViewModel[];
}

// Sales Tax Result View Models
export interface SalesTaxTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: SalesTaxViewModel[];
}

export interface SalesTaxResultViewModel extends ResultViewModel {
  data: SalesTaxViewModel;
}

export interface SalesTaxListResultViewModel extends ResultViewModel {
  data: SalesTaxViewModel[];
}

// Sales Tax Model Default Initializer
export const salesTaxDefaultValue: SalesTaxViewModel = {
  id: 0,
  name: '',
  description: '',
  abbreviation: '',
  rate: 0,
};

export const salesTaxTableDefaultValue: SalesTaxTableResultViewModel = {
  data: [],
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
  isSuccess: true,
  errorMessage: undefined,
};
