import axios from 'axios';

import {
  BillingAddressTableViewModel,
  BillingAddressViewModel,
} from '@models/BillingAddress';
import { getDataUrl } from '@utils/dataUrls';
import { api } from 'src/config/apiEndpoints';

export const BillingAddressService = {
  create: async function (billing: BillingAddressViewModel) {
    const createUrl = `${api.BASE_URL}${api.BILLING_ADDRESSES_ENDPOINT}`;

    return axios.post(createUrl, billing);
  },
  getList: async function (
    page?: number,
    pageSize?: number,
    searchFields?: string,
    search?: string,
    filters?: string
  ) {
    let dataUrl = getDataUrl(
      api.BASE_URL,
      api.BILLING_ADDRESSES_ENDPOINT,
      page,
      pageSize,
      searchFields,
      search
    );
    if (filters) {
      dataUrl = dataUrl + filters;
    }

    return axios.get(dataUrl).then((response) => {
      return response.data as Promise<BillingAddressTableViewModel>;
    });
  },
  getById: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.BILLING_ADDRESSES_ENDPOINT}/${id}`;

    return axios.get(dataUrl);
  },
  update: async function (id: number, billing: BillingAddressViewModel) {
    const updateUrl = `${api.BASE_URL}${api.BILLING_ADDRESSES_ENDPOINT}/${id}`;

    return axios.put(updateUrl, billing);
  },
  delete: async function (id: number) {
    const deleteURL = `${api.BASE_URL}${api.BILLING_ADDRESSES_ENDPOINT}/${id}`;

    return axios.delete(deleteURL);
  },
};
