interface Props {
  height: string;
  width: string;
}

export const DropDownIcon: React.FC<Props> = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.375 1.8125L6 6.1875L1.625 1.8125"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
