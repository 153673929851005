import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';
import { CustomerViewModel } from './Customer';
import { RecurringInvoiceDetailViewModel } from './RecurringInvoiceDetail';

export const recurringInvoiceValidation = Yup.object().shape({
  customerID: Yup.number().required('Customer field is Required'),
  contactID: Yup.number().required('Contact field is Required'),
  shippingAddressID: Yup.number().required(
    'Shipping Address field is Required'
  ),
  billingAddressID: Yup.number().required('Billing Address field is Required'),

  poNumber: Yup.string().max(
    20,
    'PO Number exceeds the maximum length of 20 characters'
  ),
  notes: Yup.string()
    .max(200, 'Notes exceeds the maximum length of 200 characters')
    .nullable(),
});

export interface RecurringInvoiceViewModel {
  id: number;
  recurringInvoiceNumber: string;
  poNumber: string;
  date: string;
  paymentDueDate: number;
  notes: string;
  status: number;
  repeatType: number;
  weekdaySchedule?: number;
  monthdaySchedule?: number;
  monthSchedule?: number;
  customRepeatInterval?: number;
  customIntervalType?: number;
  firstInvoiceDate: string;
  recurrenceEndType: number;
  recurrenceEndAfter?: number;
  recurrenceEndOn?: string;
  timeZone: string;
  customer?: CustomerViewModel;
  customerID?: number;
  contactID: number;
  contactEmail: string;
  contactPhone: string;
  isSameShipping: boolean;
  shippingAddressID: number;
  billingAddressID: number;
  isActive: true;
  recurringInvoiceDetails: RecurringInvoiceDetailViewModel[];
}

export interface RecurringInvoiceVwViewModel {
  id: number;
  invoiceNumber: string;
  status: number;
  statusDisplayString?: string;
  customerID: number;
  customerCompanyName: string;
  subTotal?: number;
  taxAmount?: number;
  total?: number;
}

export interface RecurringInvoiceTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: RecurringInvoiceVwViewModel[];
}

export interface RecurringInvoiceResultViewModel extends ResultViewModel {
  data: RecurringInvoiceViewModel;
}

// Recurring Invoice Default Values
export const recurringInvoiceDefaultValue: RecurringInvoiceViewModel = {
  id: 0,
  recurringInvoiceNumber: '',
  poNumber: '',
  date: '',
  paymentDueDate: 0,
  notes: '',
  status: 1,
  repeatType: 1,
  weekdaySchedule: 1,
  monthdaySchedule: 1,
  monthSchedule: 1,
  customRepeatInterval: 1,
  customIntervalType: 1,
  firstInvoiceDate: '',
  recurrenceEndType: 1,
  recurrenceEndAfter: 1,
  recurrenceEndOn: '',
  timeZone: '',
  customer: undefined,
  customerID: 0,
  contactID: 0,
  contactEmail: '',
  contactPhone: '',
  isSameShipping: false,
  shippingAddressID: 0,
  billingAddressID: 0,
  isActive: true,
  recurringInvoiceDetails: [],
};

export const recurringInvoiceTableDefaultValue: RecurringInvoiceTableResultViewModel =
  {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    isSuccess: true,
    errorMessage: undefined,
  };

export const getStatusText = (statusValue: number): any => {
  const statusOption = recurringInvoiceStatusSelectOptions.find(
    (option) => option.value === statusValue.toString()
  );
  return statusOption ? statusOption.text : '';
};

export const paymentDueDateSelectOptions = [
  'On Receipt',
  'Within 7 Days',
  'Within 14 Days',
  'Within 30 Days',
  'Within 45 Days',
  'Within 60 Days',
  'Within 90 Days',
].map((dueDate, index) => ({ value: (index + 1).toString(), text: dueDate }));

export const recurringInvoiceStatusSelectOptions = [
  'Draft',
  'Active',
  'Suspended',
  'Completed',
  'Cancelled',
  'Voided',
].map((status, index) => ({ value: (index + 1).toString(), text: status }));

export const repeatTypeSelectOptions = [
  'Daily',
  'Weekly',
  'Monthly',
  'Yearly',
  'Custom',
].map((type, index) => ({ value: (index + 1).toString(), text: type }));

export const weekdayScheduleSelectionOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
].map((week, index) => ({ value: (index + 1).toString(), text: week }));

export const monthdayScheduleSelectOptions = [
  '1st',
  '2nd',
  '3rd',
  ...Array.from({ length: 28 }, (_, i) => (i + 3).toString() + 'th'),
].map((monthday, index) => ({ value: (index + 1).toString(), text: monthday }));

export const monthScheduleSelectOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((month, index) => ({ value: (index + 1).toString(), text: month }));

export const customIntervalTypeSelectOptions = [
  'Day (s)',
  'Week (s)',
  'Month (s)',
  'Year (s)',
].map((endType, index) => ({ value: (index + 1).toString(), text: endType }));

export const recurrenceEndTypeSelectOptions = ['After', 'On', 'Never'].map(
  (endType, index) => ({ value: (index + 1).toString(), text: endType })
);

export const timeZoneSelectOptions = [
  'UTC-12:00',
  'UTC-11:00',
  'UTC-10:00',
  'UTC-09:00',
  'UTC-08:00',
  'UTC-07:00',
  'UTC-06:00',
  'UTC-05:00',
  'UTC-04:00',
  'UTC-03:00',
  'UTC-02:00',
  'UTC-01:00',
  'UTC',
  'UTC+01:00',
  'UTC+02:00',
  'UTC+03:00',
  'UTC+04:00',
  'UTC+05:00',
  'UTC+06:00',
  'UTC+07:00',
  'UTC+08:00',
  'UTC+09:00',
  'UTC+10:00',
  'UTC+11:00',
  'UTC+12:00',
].map((timeZone, index) => ({ value: (index + 1).toString(), text: timeZone }));
