import { FieldArray, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  DeleteButton,
  FormikDateInput,
  SubmitButton,
} from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { Space } from '@components/Space';
import {
  SalesTaxResultViewModel,
  SalesTaxViewModel,
  salesTaxDefaultValue,
  salesTaxValidation,
} from '@models/SalesTax';
import { SalesTaxService } from '@services/SalesTax';

interface Props {
  taxId?: number;
  onClose?: () => void;
  onDelete?: () => void;
}

export const SalesTaxEdit: React.FC<Props> = ({ taxId, onClose, onDelete }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [salesTax, setSalesTax] = useState(salesTaxDefaultValue);

  const loadData = () => {
    SalesTaxService.getById(Number(taxId))
      .then((response) => response.data as Promise<SalesTaxResultViewModel>)
      .then((result) => {
        if (result.isSuccess) {
          loadSalesTaxRates(result.data);
          console.log('Success: Fetched the record.');
        } else {
          console.log(`Error: Failed to get record. ${result.errorMessage}`);
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  const loadSalesTaxRates = (salesTax: SalesTaxViewModel) => {
    SalesTaxService.getSalesTaxRates(Number(taxId))
      .then((result) => {
        if (result.isSuccess) {
          salesTax.salesTaxRates = result.data;
          setSalesTax(salesTax);
          setIsLoading(false);
          console.log('Success: Fetched the record.');
        } else {
          console.log(`Error: Failed to get record. ${result.errorMessage}`);
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [id]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <CloseButton onClick={onClose} />

        <Box size="medium">
          <Formik
            initialValues={salesTax}
            validationSchema={salesTaxValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              SalesTaxService.update(Number(taxId), values)
                .then((response) => {
                  if (response.status == 204) {
                    window.location.reload();
                  } else {
                    console.log('Error: Failed to update a record.');
                    alert('Error: Failed to update record.');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <>
                  <form method="POST" onSubmit={formikProps.handleSubmit}>
                    <FormSectionContainer>
                      <FormikInput label="Name" name="name" />
                      <FormikInput label="Description" name="description" />
                      <FormikInput label="Abbreviation" name="abbreviation" />
                      <FieldArray
                        name="salesTaxRates"
                        render={(arrayHelpers) => (
                          <div className="flex flex-col space-y-4 content-start">
                            {formikProps.values.salesTaxRates &&
                              formikProps.values.salesTaxRates.map(
                                (rate, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-row items-end"
                                  >
                                    <FormikInput
                                      label="Rate"
                                      name={`salesTaxRates[${index}].rate`}
                                      value={rate.rate}
                                    />
                                    <FormikDateInput
                                      label="Effective Date"
                                      name={`salesTaxRates[${index}].effectiveDate`}
                                      value={rate.effectiveDate}
                                    />

                                    <PrimaryButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </PrimaryButton>
                                  </div>
                                )
                              )}

                            <PrimaryButton
                              onClick={() =>
                                arrayHelpers.push({
                                  id: 0,
                                  rate: '',
                                  effectiveDate: '',
                                  salesTaxId: taxId,
                                })
                              }
                            >
                              + Rate
                            </PrimaryButton>
                          </div>
                        )}
                      />
                      <Space />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <div className="flex space-x-4">
                        <CancelButton onClick={onClose} />

                        <DeleteButton onClick={onDelete} />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </div>
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
