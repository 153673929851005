import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { SalesTaxCreate } from './SalesTaxCreate';

import { AddRecord } from '@components/ActionButtons';
import { BoxHeaderTitle } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { Modal } from '@components/Modal';
import { routes } from '@config/routes';

export const SalesTax = () => {
  const [showCreateTax, setShowCreateTax] = useState(false);

  const baseBreadCrumbs = [{ link: 'Settings', to: '' }];

  const isOnCustomersPage = location.pathname === routes.SALES_TAX;

  const breadCrumbLinks = isOnCustomersPage
    ? baseBreadCrumbs
    : [...baseBreadCrumbs, { link: 'Sales Tax', to: routes.SALES_TAX }];

  return (
    <>
      <div className="p-4 space-y-4">
        <div className="flex justify-between items-center">
          <div className="text-sm font-semibold text-gray-500">
            Revenue and Collections
          </div>
          <div>
            <BreadCrumbs links={breadCrumbLinks} />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center">
            <BoxHeaderTitle>
              <GridHeaderMenuTitle>
                <div className="text-2xl font-black">Sales Tax</div>
              </GridHeaderMenuTitle>
            </BoxHeaderTitle>

            <div className="">
              <div className="flex flex-row space-x-1">
                <AddRecord
                  label="New Sales Tax"
                  onClick={() => setShowCreateTax(true)}
                />
              </div>
              {showCreateTax && (
                <>
                  <Modal show={true}>
                    <SalesTaxCreate onClose={() => setShowCreateTax(false)} />
                  </Modal>
                </>
              )}
            </div>
          </div>

          <div className="p-4 bg-white rounded-b-xl rounded-tr-xl">
            <BoxBodyContainer>
              <Outlet />
            </BoxBodyContainer>
          </div>
        </div>
      </div>
    </>
  );
};
