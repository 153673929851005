import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';

export const EstimateAndQuoteDetailValidation = Yup.object().shape({});

export interface EstimateAndQuoteDetailSalesTaxViewModel {
  id: number;
  estimateDetailID: number;
  salesTaxID: number;
  salesTaxAbbreviation: string;
  salesTaxAmount: number;
  rate: number;
}

export interface EstimateAndQuoteDetailSalesTaxTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: EstimateAndQuoteDetailSalesTaxViewModel[];
}

export interface EstimateAndQuoteDetailSalesTaxResultViewModel
  extends ResultViewModel {
  data: EstimateAndQuoteDetailSalesTaxViewModel;
}

export const estimateAndQuoteDetailSalesTaxDefaultValue: EstimateAndQuoteDetailSalesTaxViewModel =
  {
    id: 0,
    estimateDetailID: 0,
    salesTaxID: 0,
    salesTaxAbbreviation: '',
    salesTaxAmount: 0,
    rate: 0,
  };

export const estimateAndQuoteDetailSalesTaxTableDefaultValue: EstimateAndQuoteDetailSalesTaxTableResultViewModel =
  {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    isSuccess: true,
    errorMessage: undefined,
  };
