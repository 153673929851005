import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

export const oidcConfig: AuthProviderProps = {
  authority: 'https://auth.dscodelab.com/',
  client_id: 'tuos-ui',
  redirect_uri: 'http://localhost:8080/',
  scope: 'openid profile tuos-api',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback() {
    // You must provide an implementation of onSigninCallback to oidcConfig to remove the payload
    // from the URL upon successful login.
    // Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.

    window.history.replaceState({}, document.title, window.location.pathname);
  },
};
