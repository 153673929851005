import { createHashRouter } from 'react-router-dom';

import App from './App';
import { accountingRoutes } from './page-routes/AccountingRoutes';
import { revenueAndCollectionRoutes } from './page-routes/RevenueAndCollectionRoutes';
import { Transactions } from './pages/Accounting/Transactions';
import { DashBoard } from './pages/Dashboard/Dashboard';
import { Home } from './pages/Home';
import {
  Category,
  CategoryList,
  CategoryCreate,
  CategoryEdit,
} from './pages/Inventory/Category';
import { Inventories } from './pages/Inventory/Inventories';
import { ProductandServices } from './pages/Inventory/Product/Product';
import { ProductCreate } from './pages/Inventory/Product/ProductCreate';
import { ProductDetails } from './pages/Inventory/Product/ProductDetails';
import { ProductEdit } from './pages/Inventory/Product/ProductEdit';
import { ProductList } from './pages/Inventory/Product/ProductList';
import { Bills } from './pages/Purchase/Bills';
import { PaymentsSetup } from './pages/Purchase/PaymentsSetUp';
import { ProductsAndServicesonPurchase } from './pages/Purchase/ProductsAndServices';
import { Vendor } from './pages/Purchase/Vendor';
import { Comparative } from './pages/Reports/ProfitAndLoss/Comparative';
import { Monthly } from './pages/Reports/ProfitAndLoss/Monthly';
import { Quarter } from './pages/Reports/ProfitAndLoss/Quarter';
import { YeartoDate } from './pages/Reports/ProfitAndLoss/YearToDate';
import {
  SalesTax,
  SalesTaxCreate,
  SalesTaxEdit,
  SalesTaxList,
} from './pages/Setup/SalesTax';

const inventories = {
  path: 'inventory',
  element: <Inventories />,
  children: [
    {
      path: 'categories',
      element: <Category />,
      children: [
        { index: true, element: <CategoryList /> },
        { path: 'create', element: <CategoryCreate /> },
        { path: ':id/edit', element: <CategoryEdit /> },
      ],
    },
    {
      path: 'products-and-services',
      element: <ProductandServices />,
      children: [
        { index: true, element: <ProductList /> },
        { path: 'create', element: <ProductCreate /> },
        { path: ':id/details', element: <ProductDetails /> },
        { path: ':id/edit', element: <ProductEdit /> },
      ],
    },
  ],
};

const settings = {
  path: 'settings/sales-taxes',
  element: <SalesTax />,
  children: [
    { index: true, element: <SalesTaxList /> },
    { path: 'create', element: <SalesTaxCreate /> },
    { path: ':id/edit', element: <SalesTaxEdit /> },
  ],
};

export const AppRouter = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <Home /> },
      { path: 'dashboard', element: <DashBoard /> },
      revenueAndCollectionRoutes,
      inventories,
      settings,
      accountingRoutes,
      {
        path: 'purchase-and-payments/bills',
        element: <Bills />,
      },
      {
        path: 'purchase-and-payments/payment-set-up',
        element: <PaymentsSetup />,
      },

      { path: 'purchase-and-payments/vendors', element: <Vendor /> },
      {
        path: 'purchase-and-payments/products-and-services',
        element: <ProductsAndServicesonPurchase />,
      },
      { path: 'accounting/transactions', element: <Transactions /> },
      {
        path: '/reports/profitandloss/comparative',
        element: <Comparative />,
      },
      {
        path: 'reports/profitandloss/quarter',
        element: <Quarter />,
      },
      {
        path: 'reports/profitandloss/year-to-date',
        element: <YeartoDate />,
      },
      {
        path: 'reports/profitandloss/monthly',
        element: <Monthly />,
      },
    ],
  },
]);
