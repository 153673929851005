import React from 'react';

interface Props {
  children?: React.ReactNode;
}

export const HeaderContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex font-bold text-4xl text-black  p-4   ">{children}</div>
  );
};
