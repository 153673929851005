interface Props {
  children?: React.ReactNode;
}

export const TableDataRight: React.FC<Props> = ({ children }) => {
  return (
    <td className="whitespace-nowrap px-1 pt-1 pb-2 text-xs text-center font-normal align-top space-x-2 text-gray-900">
      {children}
    </td>
  );
};
