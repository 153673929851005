import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { Box } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { routes } from '@config/routes';

export const Currency: React.FC = () => {
  return (
    <>
      <Box>
        <BoxBodyContainer>
          <BreadCrumbs
            links={[
              { link: 'Revenue and Collections', to: `` },
              { link: 'Currency', to: `${routes.CURRENCY}` },
            ]}
          />
        </BoxBodyContainer>
        <BoxBodyContainer>
          <Outlet />
        </BoxBodyContainer>
      </Box>
    </>
  );
};
