import { format } from 'date-fns';
import { Formik } from 'formik';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  FormikDateInput,
  FormikInput,
  FormikSelect,
  FormikTextArea,
  SubmitButton,
} from '@components/Form';
import { Space } from '@components/Space';
import {
  paymentDefaultValue,
  paymentMethodSelectOptions,
  PaymentValidation,
} from '@models/Payment';
import { PaymentService } from '@services/Payment';

interface Props {
  invoiceId?: number;
  onClose?: () => void;
}

export const InvoiceRecordPayment: React.FC<Props> = ({
  invoiceId,
  onClose,
}) => {
  return (
    <>
      <CloseButton onClick={onClose} />

      <Box size="medium">
        <Formik
          initialValues={paymentDefaultValue}
          validationSchema={PaymentValidation}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) => {
            values.invoiceId = invoiceId;
            values.date = format(new Date(values.date), 'yyyy-MM-dd');
            PaymentService.create(values)
              .then((response) => {
                if (response.status == 201) {
                  window.location.reload();
                } else {
                  console.log('Error: Failed to create a record.');
                }
              })
              .catch((error) => {
                alert('Error: Failed to handle the request.');
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            return (
              <>
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <FormikDateInput label="Date" name="date" />

                    <FormikInput label="Amount" name="amount" />

                    <FormikSelect
                      label="Method"
                      name="method"
                      selection={[{ text: '', value: '0' }].concat(
                        paymentMethodSelectOptions
                      )}
                    />

                    <FormikInput label="Account" name="account" />

                    <FormikTextArea label="Notes" name="notes" />
                    <Space />
                  </FormSectionContainer>
                  <FormButtonsContainer>
                    <CancelButton onClick={onClose} />

                    <SubmitButton
                      label="Save"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    />
                  </FormButtonsContainer>
                </form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
