import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { SelectOption } from './common/SelectOption';
import { TableViewModel } from './common/TableViewModel';

export const ChartOfAccountValidation = Yup.object().shape({
  type: Yup.string()
    .max(100, 'Type exceeds the maximum length of 100 characters')
    .required('Type is a required field'),
  name: Yup.string()
    .max(100, 'Name exceeds the maximum length of 100 characters')
    .required('Name is a required field'),
  description: Yup.string()
    .max(200, 'Description exceeds the maximum length of 200 characters')
    .nullable(),
  currency: Yup.string()
    .max(50, 'Currency exceeds the maximum length of 50 characters')
    .nullable(),
});

export interface ChartOfAccountViewModel {
  id: number;
  account: number;
  description?: string;
  type: string;
  name: string;
  currency: string;
  isActive: boolean;
}

export interface ChartOfAccountTypeViewModel {
  description?: string;
  typeGroup: string;
  name: string;
}

export interface ChartOfAccountTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: ChartOfAccountViewModel[];
}

export interface ChartOfAccountTypeTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: ChartOfAccountTypeViewModel[];
}

export interface ChartOfAccountResultViewModel extends ResultViewModel {
  data: ChartOfAccountViewModel;
}

export interface ChartOfAccountSelectOptionResultViewModel
  extends ResultViewModel {
  data: SelectOption[];
}

// ChartOfAccount Model Default Initializer
export const ChartOfAccountDefaultValue: ChartOfAccountViewModel = {
  id: 0,
  account: 0,
  description: '',
  type: '',
  name: '',
  currency: '',
  isActive: true,
};

export const categoryTableDefaultValue: ChartOfAccountTableResultViewModel = {
  data: [],
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
  isSuccess: true,
  errorMessage: undefined,
};
