import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';

import { Layout } from './pages/Layout/Layout';

const App = () => {
  const auth = useAuth();

  axios.interceptors.request.use((config) => {
    if (auth.user) {
      config.headers.Authorization = `Bearer ${auth.user.access_token}`;
    }

    return config;
  });

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <>
        <Layout>
          <Outlet />
        </Layout>
      </>
    );
  } else {
    auth.signinRedirect();
    return (
      <>
        <div>Redirecting...</div>
      </>
    );
  }
};

export default App;
