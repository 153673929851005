import { Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';

import { CustomerEditContext } from './CustomerEdit';

import { CloseButton } from '@components/ActionButtons';
import {
  FormSectionContainer,
  FormButtonsContainer,
} from '@components/Container';
import { FormikInput, CancelButton, SubmitButton } from '@components/Form';
import { ActionableSelect } from '@components/Form/ActionableSelect';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import {
  ContactViewModel,
  contactDefaultValue,
  ContactValidation,
} from '@models/Contact';
import { ContactService } from '@services/Contact';
import { CustomerService } from '@services/Customer';

export const CustomerEditContactSelect = () => {
  const { customer, setCustomer } = useContext(CustomerEditContext);
  const [isCustomerContactsLoading, setIsCustomerContactsLoading] =
    useState(true);
  const customerContacts = customer.contacts || [];
  const [customerContactSelectOptions, setCustomerContactSelectOptions] =
    useState<FormikSelectOption[]>([]);
  const [isShowAddContact, setIsShowAddContact] = useState(false);
  const [isShowEditContact, setIsShowEditContact] = useState(false);
  const [contactToEdit, setContactToEdit] = useState<ContactViewModel | null>();
  const [isLoading, setIsLoading] = useState(true);

  const loadCustomerContacts = () => {
    CustomerService.getCustomerContacts(Number(customer.id)).then((result) => {
      setCustomer((prevState) => ({
        ...prevState,
        contacts: result.data,
      }));

      setIsCustomerContactsLoading(false);
    });
  };

  const loadContactOptions = () => {
    const customerContactSelection = [{ value: '0', text: '' }].concat(
      customerContacts.map((row) => ({
        value: `${row.id}`,
        text: `${row.firstname} ${row.lastname}`,
      }))
    );

    setCustomerContactSelectOptions(customerContactSelection);
  };

  useEffect(() => {
    loadContactOptions();
  }, [customer.contacts]);

  useEffect(() => {
    if (!isCustomerContactsLoading) {
      setIsLoading(false);
    }
  }, [isCustomerContactsLoading]);

  useEffect(() => {
    loadCustomerContacts();
  }, [customer.id]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <ActionableSelect
          label="Contact"
          value={`${customer.contactID}`}
          selection={customerContactSelectOptions}
          onAddAction={() => {
            setIsShowAddContact(true);
          }}
          onEditAction={() => {
            if (customer.contactID == undefined || customer.contactID == 0)
              return;

            const selectedContact = customerContacts.find(
              (row) => row.id == customer.contactID
            );

            if (selectedContact == undefined) return;

            setContactToEdit(selectedContact);
            setIsShowEditContact(true);
          }}
          onDeleteAction={() => {
            const deleteContact = customerContacts.find(
              (row) => row.id == customer.contactID
            );

            if (deleteContact == undefined) return;

            ContactService.delete(deleteContact.id).then((response) => {
              if (response.status === 204) {
                const updatedContacts = customerContacts.filter(
                  (row) => row.id !== customer.contactID
                );

                setCustomer({
                  ...customer,
                  contactID: 0,
                  contacts: updatedContacts,
                });
              }
            });
          }}
          onDuplicateAction={() => {
            const selectedContact = customerContacts.find(
              (row) => row.id == customer.contactID
            );

            if (selectedContact == undefined) return;

            selectedContact.id = 0;
            ContactService.create(selectedContact).then((response) => {
              if (response.status === 201) {
                const newContact = {
                  ...selectedContact,
                  id: response.data.data.id,
                };

                const updatedContacts = customerContacts.concat(newContact);

                setCustomer({
                  ...customer,
                  contacts: updatedContacts,
                });

                setContactToEdit(newContact);
                setIsShowEditContact(true);
              }
            });
          }}
          onChange={(e) => {
            const updateCustomer = {
              ...customer,
              contactID: Number(e.target.value),
            };
            setCustomer(updateCustomer);
          }}
        />

        {isShowAddContact && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setIsShowAddContact(false)} />

              <Formik
                initialValues={contactDefaultValue}
                validationSchema={ContactValidation}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  values.customerId = Number(customer.id);

                  ContactService.create(values).then((response) => {
                    if (response.status === 201) {
                      values.id = response.data.data.id;
                      const updatedContacts = customerContacts.concat(values);

                      setCustomer({
                        ...customer,
                        contactID: values.id,
                        contacts: updatedContacts,
                      });

                      actions.resetForm();
                      setIsShowAddContact(false);
                    }
                  });
                }}
              >
                {(formikProps) => {
                  return (
                    <>
                      <form method="POST" onSubmit={formikProps.handleSubmit}>
                        <FormSectionContainer>
                          <FormikInput label="Firstname" name="firstname" />
                          <FormikInput label="Lastname" name="lastname" />
                          <FormikInput label="Email" name="email" />
                          <FormikInput label="Phone" name="phone" />
                          <FormikInput label="Fax" name="fax" />
                        </FormSectionContainer>
                        <FormButtonsContainer>
                          <CancelButton
                            onClick={() => {
                              setIsShowAddContact(false);
                              formikProps.resetForm();
                            }}
                          />

                          <SubmitButton
                            label="Save"
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          />
                        </FormButtonsContainer>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}

        {isShowEditContact && contactToEdit && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setIsShowEditContact(false)} />

              <Formik
                initialValues={contactToEdit}
                validationSchema={ContactValidation}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  ContactService.update(values.id, values).then((response) => {
                    if (response.status === 204) {
                      const updatedContacts = customerContacts.map((row) =>
                        row.id === values.id ? values : row
                      );
                      setCustomer({
                        ...customer,
                        contactID: values.id,
                        contacts: updatedContacts,
                      });

                      actions.resetForm();
                      setIsShowEditContact(false);
                    }
                  });
                }}
              >
                {(formikProps) => {
                  return (
                    <>
                      <form method="POST" onSubmit={formikProps.handleSubmit}>
                        <FormSectionContainer>
                          <FormikInput label="Firstname" name="firstname" />
                          <FormikInput label="Lastname" name="lastname" />
                          <FormikInput label="Email" name="email" />
                          <FormikInput label="Phone" name="phone" />
                          <FormikInput label="Fax" name="fax" />
                        </FormSectionContainer>
                        <FormButtonsContainer>
                          <CancelButton
                            onClick={() => {
                              setIsShowEditContact(false);
                              formikProps.resetForm();
                            }}
                          />

                          <SubmitButton
                            label="Save"
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          />
                        </FormButtonsContainer>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </Modal>
          </>
        )}
      </>
    );
  }
};
