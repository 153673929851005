import { FieldArray, Formik } from 'formik';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelButton, FormikDateInput, SubmitButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Space } from '@components/Space';
import { salesTaxDefaultValue, salesTaxValidation } from '@models/SalesTax';
import { SalesTaxService } from '@services/SalesTax';

interface Props {
  onClose?: () => void;
}

export const SalesTaxCreate: React.FC<Props> = ({ onClose }) => {
  return (
    <>
      <CloseButton onClick={onClose} />

      <Box size="medium">
        <Formik
          initialValues={salesTaxDefaultValue}
          validationSchema={salesTaxValidation}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) => {
            SalesTaxService.create(values)
              .then((response) => {
                if (response.status == 201) {
                  window.location.reload();
                } else {
                  console.log('Error: Failed to create a record.');
                  alert('Error: Failed to create a record.');
                }
              })
              .catch((error) => {
                alert('Error: Failed to handle the request.');
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            return (
              <>
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <FormikInput label="Name" name="name" />
                    <FormikInput label="Description" name="description" />
                    <FormikInput label="Abbreviation" name="abbreviation" />

                    <FieldArray
                      name="salesTaxRates"
                      render={(arrayHelpers) => (
                        <div className="flex flex-col space-y-4 content-start">
                          {formikProps.values.salesTaxRates &&
                            formikProps.values.salesTaxRates.map(
                              (rate, index) => (
                                <div
                                  key={index}
                                  className="flex flex-row items-end"
                                >
                                  <FormikInput
                                    label="Rate"
                                    name={`salesTaxRates[${index}].rate`}
                                    value={rate.rate}
                                  />
                                  <FormikDateInput
                                    label="Effective Date"
                                    name={`salesTaxRates[${index}].effectiveDate`}
                                    value={rate.effectiveDate}
                                  />

                                  <PrimaryButton
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </PrimaryButton>
                                </div>
                              )
                            )}

                          <PrimaryButton
                            onClick={() =>
                              arrayHelpers.push({ rate: '', effectiveDate: '' })
                            }
                          >
                            + Rate
                          </PrimaryButton>
                        </div>
                      )}
                    />

                    <Space />
                  </FormSectionContainer>
                  <FormButtonsContainer>
                    <CancelButton onClick={onClose} />

                    <SubmitButton
                      label="Save"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    />
                  </FormButtonsContainer>
                </form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
