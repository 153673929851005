import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';
import { EstimateAndQuoteDetailSalesTaxViewModel } from './EstimateAndQuoteDetailSalesTax';
import { ProductViewModel } from './Product';

export const EstimateAndQuoteDetailValidation = Yup.object().shape({});

export interface EstimateAndQuoteDetailViewModel {
  id: number;
  estimateID: number;
  productID: number;
  product?: ProductViewModel;
  description: string;
  quantity: number;
  price: number;
  estimateDetailSalesTaxes: EstimateAndQuoteDetailSalesTaxViewModel[];
  subTotal: number;
}

export interface EstimateAndQuoteDetailTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: EstimateAndQuoteDetailViewModel[];
}

export interface EstimateAndQuoteDetailListResultViewModel
  extends ResultViewModel {
  data: EstimateAndQuoteDetailViewModel[];
}

export interface EstimateAndQuoteDetailResultViewModel extends ResultViewModel {
  data: EstimateAndQuoteDetailViewModel;
}

export const estimateAndQuoteDetailDefaultValue: EstimateAndQuoteDetailViewModel =
  {
    id: 0,
    estimateID: 0,
    productID: 0,
    product: undefined,
    description: '',
    quantity: 0,
    price: 0,
    estimateDetailSalesTaxes: [],
    subTotal: 0,
  };

export const estimateAndQuoteDetailTableDefaultValue: EstimateAndQuoteDetailTableResultViewModel =
  {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    isSuccess: true,
    errorMessage: undefined,
  };
