import axios from 'axios';

import { api } from '@config/apiEndpoints';
import {
  ProductSelectOptionResultViewModel,
  ProductTableResultViewModel,
  ProductViewModel,
} from '@models/Product';
import { getDataUrl } from '@utils/dataUrls';

export const ProductService = {
  create: async function (products: ProductViewModel) {
    const createUrl = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}`;

    return axios.post(createUrl, products);
  },
  getlist: async function (
    page?: number,
    pageSize?: number,
    searchFields?: string,
    search?: string,
    filters?: string
  ) {
    let dataUrl = getDataUrl(
      api.BASE_URL,
      api.PRODUCTS_ENDPOINT,
      page,
      pageSize,
      searchFields,
      search
    );

    if (filters) {
      dataUrl = dataUrl + filters;
    }

    return axios.get(dataUrl).then((response) => {
      return response.data as Promise<ProductTableResultViewModel>;
    });
  },
  getById: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}/${id}`;

    return axios.get(dataUrl);
  },
  getProductSelectOptions: async function () {
    const dataUrl = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}/select-options`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<ProductSelectOptionResultViewModel>
      );
  },
  update: async function (id: number, product: ProductViewModel) {
    const updateUrl = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}/${id}`;

    return axios.put(updateUrl, product);
  },
  delete: async function (id: number) {
    const deleteURL = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}/${id}`;

    return axios.delete(deleteURL);
  },
};
