import { DocumentIcon, FolderIcon, LinkIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import { CloseButton } from '@components/ActionButtons';
import { FormButtonsContainer } from '@components/Container';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { routes } from '@config/routes';
import { CustomerResultViewModel } from '@models/Customer';
import {
  EstimateAndQuoteResultViewModel,
  estimateAndQuoteDefaultValue,
} from '@models/EstimateAndQuote';
import { CustomerService } from '@services/Customer';
import { EstimateAndQuoteService } from '@services/EstimateAndQuote';

interface Props {
  estimateId?: number;
  onDownload?: () => void;
  onClose?: () => void;
  estimateName: string;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const EstimateAndQuoteSend: React.FC<Props> = ({
  onDownload,
  estimateId,
  onClose,
  estimateName,
}) => {
  const [estimateData, setEstimateData] = useState(
    estimateAndQuoteDefaultValue
  );
  const [customerName, setCustomerName] = useState('');
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setUploadFile(selectedFile);
  };

  const loadEstimate = () => {
    EstimateAndQuoteService.getById(Number(estimateId))
      .then(
        (response) => response.data as Promise<EstimateAndQuoteResultViewModel>
      )
      .then((result) => {
        if (result.isSuccess) {
          setEstimateData(result.data);
          if (result.data.customerID) {
            CustomerService.getById(result.data.customerID)
              .then(
                (customerResponse) =>
                  customerResponse.data as Promise<CustomerResultViewModel>
              )
              .then((customerResult) => {
                setCustomerName(customerResult.data.companyName);
              });
          }
        }
      });
  };

  const handleCopyLink = (estimateLink: string) => {
    navigator.clipboard
      .writeText(estimateLink)
      .then(() => {
        console.log('Copied Link');
      })
      .catch((error) => {
        console.log('Failed to copy Link:', error);
      });
  };

  // const handleExportToPdf = (rowId: any) => {
  //   navigate(`${routes.RNC_ESTIMATES}/${rowId}/pdf`);
  //   onDownload;
  //   console.log(rowId);
  // };

  const sendRecord = (rowId: any) => {
    EstimateAndQuoteService.sendEmail(rowId, uploadFile).then((response) => {
      if (response.status === 200) {
        console.log('successfully sent a record.');
        window.location.reload();
      } else {
        console.log('Error: Failed to send the record.');
      }
    });
  };

  useEffect(() => {
    if (estimateId) {
      loadEstimate();
    }
  }, [estimateId]);

  return (
    <>
      <div className="flex pl-4 justify-between items-center">
        <div className="w-full">Send Estimate</div>
        <div>
          <CloseButton onClick={onClose} />
        </div>
      </div>

      <div className="border-t border-b border-gray-300 space-y-4 px-4 pb-6">
        <div className="grid grid-cols-2 space-x-4 px-4">
          <div
            onClick={() =>
              handleCopyLink(
                `${window.location.origin}/#${routes.RNC_ESTIMATES}/${estimateId}/pdf`
              )
            }
            className="cols-span-1 p-8 shadow-md rounded-b-md space-y-4 hover:bg-blue-50"
          >
            <div className="text-md font-medium text-center">
              <div className="flex justify-center pb-4 text-blue-500">
                <LinkIcon height={24} width={24} />
              </div>
              Copy Link
            </div>
            <div className="text-xs text-center">
              A link to your Estimate with all details included
            </div>
          </div>
          <div
            className="cols-span-1 p-8 shadow-md rounded-b-md space-y-4 hover:bg-blue-50"
            onClick={onDownload}
          >
            <div className="text-md font-medium text-center">
              <div className="flex justify-center pb-4 text-blue-500">
                <FolderIcon height={24} width={24} />
              </div>
              Download PDF
            </div>
            <div className="text-xs text-center">
              Your Estimate all in one document
            </div>
          </div>
        </div>
        <div className="px-4">
          <div className="shadow-md rounded-md space-y-2 pt-6">
            <div className="px-8 space-y-2">
              <div className="text-md font-medium text-center">
                {estimateName}
              </div>
              <div className="flex justify-between text-gray-500 text-sm font-light">
                <div>Customer Name:</div>
                <div className="font-medium">{customerName}</div>
              </div>
              <div className="flex justify-between text-gray-500 text-sm font-light">
                <div>Date Issued:</div>
                <div className="font-medium">
                  {formatDate(estimateData.date)}
                </div>
              </div>
              <div className="font-medium text-center text-gray-500 text-sm">
                TUOS Development
              </div>
            </div>
            <div className="border-t text-sm border-gray-300 px-8 py-4 text-center">
              Valid Until:
              <span className="font-medium">
                {' '}
                {formatDate(estimateData.dueDate)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-full">
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium leading-6 text-gray-900"
        ></label>
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className="text-center">
            {uploadFile ? (
              <>
                <DocumentIcon
                  aria-hidden="true"
                  className="mx-auto h-12 w-12 text-green-300"
                />
                <div className="text-sm text-gray-600">{uploadFile.name}</div>
              </>
            ) : (
              <DocumentIcon
                aria-hidden="true"
                className="mx-auto h-12 w-12 text-gray-300"
              />
            )}

            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={handleFileChange}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">file up to 20MB</p>
          </div>
        </div>
      </div>
      <FormButtonsContainer>
        <div className="space-x-2 pt-2">
          <CancelButton onClick={onClose} />
          <OkButton onClick={() => sendRecord(estimateId)}>Send</OkButton>
        </div>
      </FormButtonsContainer>
    </>
  );
};
