import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';

export interface ContactViewModel {
  data: any;
  id: number;
  uiid: number;
  customerId?: number;
  firstname: string;
  lastname: string;
  email?: string;
  phone?: string;
  fax?: string;
  isActive?: true;
}

export interface ContactResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: ContactViewModel;
}

export interface ContactListResultViewModel extends ResultViewModel {
  data: ContactViewModel[];
}

export interface ContactTableViewModel extends TableViewModel {
  data: ContactViewModel[];
}

export const contactDefaultValue: ContactViewModel = {
  data: null,
  id: 0,
  uiid: 0,
  customerId: 0,
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  fax: '',
  isActive: true,
};

export const contactTableDefaultValue: ContactTableViewModel = {
  data: [],
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
};

export const ContactValidation = Yup.object().shape({
  firstname: Yup.string()
    .max(50, 'Firstname exceeds the maximum length of 50 characters')
    .required('First Name is Required'),
  lastname: Yup.string()
    .max(50, 'Lastname exceeds the maximum length of 50 characters')
    .required('Last Name is Required'),
  email: Yup.string()
    .max(100, 'Email exceeds the maximum length of 100 characters')
    .nullable(),
  phone: Yup.string()
    .max(100, 'Phone exceeds the maximum length of 20 characters')
    .nullable(),
  fax: Yup.string()
    .max(20, 'Fax exceeds the maximum length of 20 characters')
    .nullable(),
});
