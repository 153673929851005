import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../../Layout/HeaderContainer';

import { PageContainer } from '@components/PageContainer';
import { routes } from '@config/routes';

export const Monthly = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        links={[
          { link: 'Reports', to: `` },
          { link: 'Profit and Loss', to: `` },
          { link: 'Monthly', to: `${routes.MONTHLY}` },
        ]}
      />
      {/* change this to main content */}
      <HeaderContainer>This is Monthly Page</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
