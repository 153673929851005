import { Formik } from 'formik';
import { useEffect, useState } from 'react';

import { CloseButton } from '@components/ActionButtons/CloseButton';
import { Box } from '@components/Box';
import {
  FormSectionContainer,
  FormButtonsContainer,
} from '@components/Container';
import {
  FormikInput,
  FormikTextArea,
  CancelButton,
  SubmitButton,
} from '@components/Form';
import { FormikSelectGrouped } from '@components/Form/FormikSelectGrouped';
import { Space } from '@components/Space';
import {
  ChartOfAccountDefaultValue,
  ChartOfAccountValidation,
} from '@models/ChartOfAccount';
import { ReactSelectGroupedOption } from '@models/common/ReactSelectOption';
import { ChartOfAccountService } from '@services/ChartofAccount';

interface Props {
  onClose?: () => void;
}

export const ChartOfAccountCreate: React.FC<Props> = ({ onClose }) => {
  const [accountTypes, setAccountTypes] = useState<ReactSelectGroupedOption[]>(
    []
  );

  const loadChartOfAccountTypes = () => {
    ChartOfAccountService.getChartOfAccountTypes().then((result) => {
      const assets = result.data
        .filter((row) => row.typeGroup === 'Assets')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const liabilitiesAndCreditCards = result.data
        .filter((row) => row.typeGroup === 'LiabilitiesAndCreditCards')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const income = result.data
        .filter((row) => row.typeGroup === 'Income')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const expenses = result.data
        .filter((row) => row.typeGroup === 'Expenses')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const equity = result.data
        .filter((row) => row.typeGroup === 'Equity')
        .map((row) => ({
          value: row.name,
          label: row.name,
        }));

      const accountTypeSelections: ReactSelectGroupedOption[] = [
        {
          label: 'ASSETS',
          options: assets,
        },
        {
          label: 'LIABILITIES AND CREDIT CARDS',
          options: liabilitiesAndCreditCards,
        },
        {
          label: 'INCOME',
          options: income,
        },
        {
          label: 'EXPENSES',
          options: expenses,
        },
        {
          label: 'EQUITY',
          options: equity,
        },
      ];

      setAccountTypes(accountTypeSelections);
    });
  };

  useEffect(() => {
    loadChartOfAccountTypes();
  }, []);

  return (
    <>
      <CloseButton onClick={onClose} />
      <Box size="medium">
        <Formik
          initialValues={ChartOfAccountDefaultValue}
          validationSchema={ChartOfAccountValidation}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) =>
            ChartOfAccountService.create(values)
              .then((response) => {
                if (response.status == 201) {
                  window.location.reload();
                } else {
                  console.log('Error: Failed to create a record.');
                }
              })
              .catch((error) => {
                alert('Error: Failed to handle the request.');
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
              })
          }
        >
          {(formikProps) => {
            return (
              <>
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <FormikSelectGrouped
                      label="Account Type"
                      name="type"
                      selection={accountTypes}
                    />
                    <FormikInput label="Account Name" name="name" />
                    <FormikInput label="Account ID" name="account" />
                    <FormikTextArea label="Description" name="description" />
                    <FormikInput label="Account Currency" name="currency" />
                    <Space />
                  </FormSectionContainer>
                  <FormButtonsContainer>
                    <CancelButton onClick={onClose} />

                    <SubmitButton
                      label="Save"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    />
                  </FormButtonsContainer>
                </form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
