import React from 'react';

interface TextWrapperProps {
  text: string;
}

const TextWrapper: React.FC<TextWrapperProps> = ({ text }) => {
  const chunks = text.match(/.{1,60}/g) || [];

  return (
    <div>
      {chunks.map((chunk, index) => (
        <>
          <span key={index}>{chunk}</span>
          <br />
        </>
      ))}
    </div>
  );
};

export default TextWrapper;
