import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Toolbar,
  EditSettingsModel,
  Grid,
  DataStateChangeEventArgs,
  DataResult,
} from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';

import { ContactCreate } from './ContactCreate';
import { ContactEdit } from './ContactEdit';

import { CloseButton } from '@components/ActionButtons/CloseButton';
import { Box } from '@components/Box';
import { FormButtonsContainer } from '@components/Container';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { FilterRecord } from '@components/Grid/FilterRecord';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import { ResultViewModel } from '@models/common/ResultViewModel';
import { ContactResultViewModel } from '@models/Contact';
import { ContactService } from '@services/Contact';

export const ContactList: React.FC = () => {
  const [showEditContact, setShowEditContact] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<number>(0);
  // const navigate = useNavigate();
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [dataUrlQueryFilter, setDataUrlQueryFilter] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [showDelete, setShowDelete] = useState(false);

  let grid: Grid | null;
  let data;

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Dialog',
  };

  const toolbarOptions = [
    {
      text: 'Add',
      tooltipText: 'Add new record',
      prefixIcon: 'e-add',
      id: 'add',
    },
    {
      text: 'Details',
      tooltipText: 'Vew selected record details',
      prefixIcon: 'e-edit',
      id: 'details',
    },
    {
      text: 'Edit',
      tooltipText: 'Edit selected record',
      prefixIcon: 'e-edit',
      id: 'edit',
    },
    {
      text: 'Delete',
      tooltipText: 'Delete selected record',
      prefixIcon: 'e-delete',
      id: 'delete',
    },
  ];

  const clickHandler = (args: any) => {
    if (args.item.id === 'add') {
      setShowCreateContact(true);
    }
  };

  useEffect(() => {
    grid?.refresh();
  }, [dataUrlQueryFilter]);

  function renderComplete() {
    if (
      grid &&
      grid.dataSource instanceof Array &&
      !(grid.dataSource as object[]).length
    ) {
      const state = { skip: 0, take: 10 };
      dataStateChange(state);
    }
  }

  function dataStateChange(state: DataStateChangeEventArgs) {
    execute(state).then((gridData) => {
      if (grid) {
        grid.dataSource = gridData;
      }
    });
  }
  function execute(state: DataStateChangeEventArgs): Promise<DataResult> {
    return getData(state);
  }

  function getData(state: DataStateChangeEventArgs): Promise<DataResult> {
    const page =
      !state || !state.skip || !state.take ? 1 : state.skip / state?.take + 1;

    return ContactService.getList(
      page,
      state.take,
      undefined,
      undefined,
      dataUrlQueryFilter
    ).then((data) => {
      return { result: data.data, count: data.total };
    });
  }

  const deleteRecord = (id: number) => {
    ContactService.delete(id).then((response) => {
      if (response.status == 204) {
        console.log('Successfully deleted the record.');
        window.location.reload();
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Error: Failed to delete the record. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };
  const handleEdit = (rowId: any) => {
    //navigate(`${routes.CONTACTS}/${rowId}/edit`);
    setShowEditContact(true);
    setSelectedContactId(rowId);
  };
  const handleDelete = async (rowId: any) => {
    try {
      ContactService.getById(Number(rowId))
        .then((response) => response.data as Promise<ContactResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            setSelectedContactId(rowId);
            setContactName(result.data.firstname);
            setShowDelete(true);
          } else {
            const message = `Error: Failed to fetch category details for deletion. ${result.errorMessage}`;
            alert(message);
            console.log(message);
          }
        });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const actionButtons = (props: any) => {
    return (
      <>
        <div className="flex">
          <Menu as="div" className="absolute ml-auto">
            <Menu.Button className="-my-3 mx-5 block text-primary-500 hover:text-primary-200">
              <span className="sr-only">Open options</span>
              <ChevronDownIcon width="20" height="20" />
            </Menu.Button>
            <Menu.Items
              className={`absolute right-12 -top-1 z-50 mt-0.5 w-32 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none 
              `}
            >
              <div>
                <div className="hover:bg-primary-500 rounded-md">
                  <button
                    onClick={() => handleEdit(props.id)}
                    className="w-full text-start px-3"
                  >
                    Edit
                  </button>
                </div>
                <div className="hover:bg-primary-500 rounded-md">
                  <button
                    onClick={() => handleDelete(props.id)}
                    className="w-full text-start px-3"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </>
    );
  };

  return (
    <>
      <FilterRecord
        label="Filter"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        onFilter={() => {
          setDataUrlQueryFilter(`&nameFilters=${nameFilter}`);
        }}
        onClear={() => {
          setDataUrlQueryFilter('');
          setNameFilter('');
        }}
      />

      <Space />

      <GridComponent
        dataBound={renderComplete}
        dataSource={data}
        ref={(g) => (grid = g)}
        allowPaging={true}
        pageSettings={{ pageSize: 10 }}
        allowFiltering={true}
        editSettings={editOptions}
        toolbar={toolbarOptions}
        toolbarClick={clickHandler}
        dataStateChange={dataStateChange}
      >
        <ColumnsDirective>
          <ColumnDirective field="firstname" headerText="First Name" />
          <ColumnDirective field="lastname" headerText="Last Name" />
          <ColumnDirective field="email" headerText="Email" />
          <ColumnDirective
            headerText="Actions"
            width={100}
            template={actionButtons}
          />
        </ColumnsDirective>
        <Inject services={[Page, Toolbar]} />
      </GridComponent>

      {showEditContact && (
        <>
          <Modal show={true}>
            {selectedContactId && (
              <ContactEdit
                contactId={selectedContactId}
                onClose={() => {
                  setShowEditContact(false);
                }}
                onDelete={() => {
                  setShowEditContact(false);
                  handleDelete(selectedContactId);
                }}
              />
            )}
          </Modal>
        </>
      )}
      {showDelete && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setShowDelete(false)} />
            <Box size="medium">
              <>
                <div className="px-4 pb-2">
                  <span>Are you sure to delete Customer: {contactName}</span>
                </div>
                <FormButtonsContainer>
                  <div className="space-x-2">
                    <CancelButton onClick={() => setShowDelete(false)} />
                    <OkButton onClick={() => deleteRecord(selectedContactId)}>
                      Delete
                    </OkButton>
                  </div>
                </FormButtonsContainer>
              </>
            </Box>
          </Modal>
        </>
      )}

      {showCreateContact && (
        <>
          <Modal show={true}>
            <ContactCreate onClose={() => setShowCreateContact(false)} />
          </Modal>
        </>
      )}
    </>
  );
};
