import { Formik } from 'formik';
import { useEffect, useState } from 'react';

import { CloseButton } from '@components/ActionButtons/CloseButton';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  DeleteButton,
  FormikCheckbox,
  FormikSelectMultiple,
  SubmitButton,
} from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { Space } from '@components/Space';
import { ReactSelectOption } from '@models/common/ReactSelectOption';
import {
  ProductDefaultValue,
  ProductResultViewModel,
  productValidation,
} from '@models/Product';
import { salesTaxDefaultValue } from '@models/SalesTax';
import { ProductService } from '@services/Product';
import { SalesTaxService } from '@services/SalesTax';

const initialSalesTaxes: ReactSelectOption[] = [];

interface Props {
  productId?: number;
  onDelete?: () => void;
  onClose?: () => void;
}

export const ProductEdit: React.FC<Props> = ({
  productId,
  onClose,
  onDelete,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSalesTaxLoading, setIsSalesTaxLoading] = useState(true);
  const [isProductLoading, setIsProductLoading] = useState(true);

  const [product, setProduct] = useState(ProductDefaultValue);

  const [salesTaxSelectOptions, setSalesTaxSelectOptions] =
    useState<ReactSelectOption[]>(initialSalesTaxes);

  const [productSalesTaxes, setProductSalesTaxes] =
    useState<ReactSelectOption[]>();

  const handleProductSalesTaxesOnChange = (
    option: readonly ReactSelectOption[]
  ) => {
    const selectedSalesTaxOptions = option.map((row) => ({
      value: row.value,
      label: row.label,
    }));
    setProductSalesTaxes(selectedSalesTaxOptions);
  };

  const loadSalesTaxes = () => {
    SalesTaxService.getList()
      .then((result) => {
        const selectOptions = result.data.map((row) => ({
          value: row.id.toString(),
          label: row.abbreviation,
        }));

        setSalesTaxSelectOptions(selectOptions);

        setIsSalesTaxLoading(false);
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  const loadProduct = () => {
    ProductService.getById(Number(productId))
      .then((response) => response.data as Promise<ProductResultViewModel>)
      .then((result) => {
        if (result.isSuccess) {
          if (result.data.productSalesTaxes) {
            const selectedProductSalesTaxOptions =
              result.data.productSalesTaxes.map((row) => ({
                value: row.salesTaxId.toString(),
                label: row.salesTaxId.toString(),
              }));
            setProductSalesTaxes(selectedProductSalesTaxOptions);
          }

          setProduct(result.data);

          setIsProductLoading(false);

          console.log('Success: Fetched the record.');
        } else {
          console.log('Error: Failed to get record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  useEffect(() => {
    loadProduct();
    loadSalesTaxes();
  }, []);

  useEffect(() => {
    if (!isSalesTaxLoading && !isProductLoading) {
      if (productSalesTaxes) {
        const selectedSalesTaxOptions = productSalesTaxes.map((row) => ({
          value: row.value,
          label:
            salesTaxSelectOptions.find((o) => o.value === row.value)?.label ??
            '',
        }));

        setProductSalesTaxes(selectedSalesTaxOptions);
      }

      setIsLoading(false);
    }
  }, [isSalesTaxLoading, isProductLoading]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <CloseButton onClick={onClose} />

        <Box size="medium">
          <Formik
            initialValues={product}
            validationSchema={productValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              if (productSalesTaxes) {
                values.productSalesTaxes = productSalesTaxes.map((row) => ({
                  id: 0,
                  productId: Number(productId),
                  salesTaxId: parseInt(row.value.toString()),
                  salesTax: salesTaxDefaultValue,
                }));
              }
              ProductService.update(Number(productId), values)
                .then((response) => {
                  if (response.status == 204) {
                    window.location.reload();
                  } else {
                    console.log('Error: Failed to update record.');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <>
                  <form method="POST" onSubmit={formikProps.handleSubmit}>
                    <FormSectionContainer>
                      <FormikInput label="Name" name="name" />
                      <FormikInput label="Description" name="description" />
                      <FormikInput label="Price" name="price" />
                      <div className="flex">
                        <FormikCheckbox label="Sell this" name="isSales" />
                        <FormikCheckbox label="Buy This" name="isActive" />
                      </div>

                      <FormikSelectMultiple
                        label="Sales Tax"
                        name="productSalesTaxes"
                        selection={salesTaxSelectOptions}
                        selectedOptions={productSalesTaxes}
                        changeHandler={handleProductSalesTaxesOnChange}
                        maxMenuHeight={100}
                      />

                      <Space />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <div className="flex space-x-4">
                        <CancelButton onClick={onClose} />

                        <DeleteButton onClick={onDelete} />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </div>
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
