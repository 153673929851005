import {
  ArrowLeftOnRectangleIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import 'react-toastify/dist/ReactToastify.css';

import { ButtonCLick } from './Header/ButtonCLicker/ButtonClick';
import { Header } from './Header/Header';
// import { MobileSideBar } from './SideBar/MobileSideBar';
import { SideBar } from './SideBar/SideBar';
import { SideBarResponsive } from './SideBar/SideBarResponsive';

// import { CloseButton } from '@components/ActionButtons';
import { BellIcon } from '@components/ButtonSwitchContainer/React-Icon/BellIcon';
import { EnvelopIcon } from '@components/ButtonSwitchContainer/React-Icon/EnvelopIcon';
import { SideBarIcon } from '@components/ButtonSwitchContainer/React-Icon/SideBarIcon';
import { TuosDevSide } from '@components/ButtonSwitchContainer/TuosDevSide';
import { UserMenus } from '@components/UserMenus';

interface IProps {
  children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [showSideBarResponsive, setShowSideBarResponsive] = useState(false);
  const [sideBarBot, setSideBar] = useState(false);
  const auth = useAuth();

  const handleSideBarButton = () => {
    setShowSideBar(!showSideBar);
    setSideBar(true);
  };

  const setSideBarClass = sideBarBot ? '' : 'rotate-0';

  return (
    <>
      {/* Desktop */}
      <div className="lg:block md:hidden hidden lg:h-full lg:w-full">
        <div className="grid h-full gap-0 grid-cols-6 ">
          {showSideBar && (
            <>
              <SideBar />
            </>
          )}

          <div
            className={`h-full ${showSideBar ? 'col-span-5' : 'col-span-6'}`}
          >
            <div>
              <Header>
                <div className="w-full flex h-full">
                  <div className="flex bg-[#53CC96] w-8 pl-6">
                    <button
                      className={`${setSideBarClass}`}
                      onClick={handleSideBarButton}
                    >
                      <SideBarIcon height="24" width="24" />
                    </button>
                  </div>

                  <div className="flex items-center justify-end h-full pr-[2.5rem] w-full">
                    <div className=" flex text-primary-700">
                      <ButtonCLick>
                        <BellIcon height="20" width="20" />
                      </ButtonCLick>
                      <ButtonCLick>
                        <EnvelopIcon height="20" width="20" />
                      </ButtonCLick>
                    </div>
                    {auth.isAuthenticated && (
                      <>
                        <div
                          onClick={() => {
                            auth.signoutRedirect();
                          }}
                          className="hover: cursor-pointer"
                        >
                          <ArrowLeftOnRectangleIcon className="h-5 w-5 text-primary-700" />
                        </div>
                        <div className="px-4">
                          <button className="">
                            {auth.user?.profile?.name}
                          </button>

                          <div className="flex justify-end">
                            <span className="">
                              {auth.user?.profile?.email}
                            </span>
                          </div>
                        </div>

                        <div>
                          <UserCircleIcon className="h-8 w-8 text-primary-700" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Header>
            </div>
            <div className="mx-4">{children}</div>
          </div>
        </div>
      </div>

      {/* Mobile & Desktop */}
      <div className="lg:hidden md:block block">
        {/* Header */}
        <div className="flex items-center flex-row justify-between bg-[#53CC96] py-5 px-5">
          <button
            onClick={() => setShowSideBarResponsive(!showSideBarResponsive)}
          >
            <SideBarIcon height="24" width="24" />
          </button>

          <TuosDevSide width="104" height="30" />

          <UserMenus />
        </div>
        <div className="flex">
          <div className="fixed h-full bg-[#E7EEEB] z-10">
            {showSideBarResponsive && (
              <>
                <div className="px-5 pt-10">
                  <SideBarResponsive
                    onClose={() => setShowSideBarResponsive(false)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="mx-4 w-full">{children}</div>
        </div>
      </div>
    </>
  );
};
