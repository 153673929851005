import { Accounting } from '../pages/Accounting/Accounting';
import { BankConnectionList } from '../pages/Accounting/BankConnection/BankConnectionList';
import { BankConnections } from '../pages/Accounting/BankConnection/BankConnections';
import {
  ChartOfAccount,
  ChartOfAccountList,
} from '../pages/Accounting/ChartOfAccount';
import { Recon } from '../pages/Accounting/Recon/Recon';
import { ReconList } from '../pages/Accounting/Recon/ReconList';
import {
  Transactions,
  TransactionsList,
} from '../pages/Accounting/Transactions';

export const accountingRoutes = {
  path: 'accounting',
  element: <Accounting />,
  children: [
    {
      path: 'chart-of-accounts',
      element: <ChartOfAccount />,
      children: [{ index: true, element: <ChartOfAccountList /> }],
    },
    {
      path: 'transactions',
      Element: <Transactions />,
      children: [{ index: true, element: <TransactionsList /> }],
    },
    {
      path: 'recon',
      Element: <Recon />,
      children: [
        {
          index: true,
          element: <ReconList />,
        },
      ],
    },
    {
      path: 'bank-connections',
      Element: <BankConnections />,
      children: [{ index: true, element: <BankConnectionList /> }],
    },
  ],
};
