import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { SelectOption } from './common/SelectOption';
import { SalesTaxViewModel } from './SalesTax';

import { TableViewModel } from '@models/common/TableViewModel';

export interface ProductViewModel {
  id: number;
  name: string;
  description: string;
  estimateId: number;
  categoryId: number;
  quantity: number;
  price: number;
  isSales: boolean;
  isPurchase?: boolean;
  isActive?: boolean;
  incomeAccountId?: number;
  expeneAccountId?: number;
  salesTaxId: number;
  productSalesTaxes: ProductsSalesTaxViewModel[];
  accountId?: number;
  createdBy?: string;
  dateCreated?: string;
  updatedBy?: string;
  dateUpdated?: string;
}

export interface ProductsSalesTaxViewModel {
  id: number;
  productId: number;
  salesTaxId: number;
  salesTax: SalesTaxViewModel;
}

// Product Result View Models

export interface ProductTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: ProductViewModel[];
}

export interface ProductResultViewModel extends ResultViewModel {
  data: ProductViewModel;
}

export interface ProductSelectOptionResultViewModel extends ResultViewModel {
  data: SelectOption[];
}

export const ProductDefaultValue: ProductViewModel = {
  id: 0,
  name: '',
  description: '',
  estimateId: 0,
  categoryId: 0,
  quantity: 0,
  price: 0,
  isSales: true,
  isPurchase: true,
  isActive: true,
  incomeAccountId: 0,
  expeneAccountId: 0,
  salesTaxId: 0,
  productSalesTaxes: [],
  accountId: 0,
  createdBy: '',
  dateCreated: '',
  updatedBy: '',
  dateUpdated: '',
};

export const productValidation = Yup.object().shape({
  name: Yup.string()
    .max(100, 'Name exceeds the maximum lenght of 100 character')
    .required('Name is Required'),
  description: Yup.string()
    .max(100, 'Description exceeds tha maximum lenght of 500 character')
    .nullable(),
});
