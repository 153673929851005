import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { Box } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { routes } from '@config/routes';

export const Transactions = () => {
  return (
    <>
      <Box>
        <BoxBodyContainer>
          <BreadCrumbs
            links={[
              {
                link: 'Accounting',
                to: ``,
              },
              {
                link: 'Transactions',
                to: `${routes.TRANSACTIONS}`,
              },
            ]}
          />
        </BoxBodyContainer>
        <Outlet />
      </Box>
    </>
  );
};
