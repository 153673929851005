import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { Box } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { routes } from '@config/routes';

export const Contact: React.FC = () => {
  return (
    <>
      <Box>
        <BoxBodyContainer>
          <GridHeaderMenuTitle>
            <div className="text-2xl font-black"> Contacts</div>
          </GridHeaderMenuTitle>

          <BreadCrumbs
            links={[
              { link: 'Revenue and Collections', to: `` },
              { link: 'Contact', to: `${routes.CONTACTS}` },
            ]}
          />
        </BoxBodyContainer>
        <BoxBodyContainer>
          <Outlet />
        </BoxBodyContainer>
      </Box>
    </>
  );
};
