import axios from 'axios';

import { BillingAddressListResultViewModel } from '@models/BillingAddress';
import { ContactListResultViewModel } from '@models/Contact';
import {
  BillingAddressesSelectOptionResultViewModel,
  CustomerContactSelectOptionResultViewModel,
  CustomerSelectOptionResultViewModel,
  CustomerTableResultViewModel,
  CustomerViewModel,
  ShippingAddressesSelectOptionResultViewModel,
} from '@models/Customer';
import { ShippingAddressListResultViewModel } from '@models/ShippingAddress';
import { getDataUrl } from '@utils/dataUrls';
import { api } from 'src/config/apiEndpoints';

export const CustomerService = {
  create: async function (customer: CustomerViewModel) {
    const createUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}`;

    return axios.post(createUrl, customer);
  },
  delete: async function (id: number) {
    const deleteURL = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}`;

    return axios.delete(deleteURL);
  },

  getList: async function (
    page?: number,
    pageSize?: number,
    searchFields?: string,
    search?: string,
    filters?: string
  ) {
    let dataUrl = getDataUrl(
      api.BASE_URL,
      api.CUSTOMERS_ENDPOINT,
      page,
      pageSize,
      searchFields,
      search
    );

    if (filters) {
      dataUrl = dataUrl + filters;
    }

    return axios.get(dataUrl).then((response) => {
      return response.data as Promise<CustomerTableResultViewModel>;
    });
  },

  getById: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}`;

    return axios.get(dataUrl);
  },

  getCustomerContacts: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}/contacts`;

    return axios
      .get(dataUrl)
      .then((response) => response.data as Promise<ContactListResultViewModel>);
  },

  getCustomerBillingAddresses: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}/billing-addresses`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<BillingAddressListResultViewModel>
      );
  },

  getCustomerShippingAddresses: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}/shipping-addresses`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<ShippingAddressListResultViewModel>
      );
  },

  getCustomerSelectOptions: async function () {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/select-options`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<CustomerSelectOptionResultViewModel>
      );
  },

  getCustomerContactsSelectOptions: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}/contacts/select-options`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<CustomerContactSelectOptionResultViewModel>
      );
  },

  getCustomerBillingAddressesSelectOptions: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}/billing-addresses/select-options`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<BillingAddressesSelectOptionResultViewModel>
      );
  },

  getCustomerShippingAddressesSelectOptions: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}/shipping-addresses/select-options`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<ShippingAddressesSelectOptionResultViewModel>
      );
  },

  update: async function (id: number, customer: CustomerViewModel) {
    const updateUrl = `${api.BASE_URL}${api.CUSTOMERS_ENDPOINT}/${id}`;

    return axios.put(updateUrl, customer);
  },
};
