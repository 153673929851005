interface Prop {
  children?: React.ReactNode;
}

export const SideBarContainer: React.FC<Prop> = ({ children }) => {
  return (
    <div className="col-span-1 h-full border-green-500 bg-white  space-y-2  w-full ">
      {children}
    </div>
  );
};
