interface Props {
  children?: React.ReactNode;
}

export const TableDataCenter: React.FC<Props> = ({ children }) => {
  return (
    <td className="whitespace-nowrap px-1 pt-1 pb-2 text-center text-gray-900 text-xs align-top font-normal">
      {children}
    </td>
  );
};
