import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';
import { InvoiceDetailSalesTaxViewModel } from './InvoiceDetailSalesTax';
import { ProductViewModel } from './Product';

export const InvoiceDetailValidation = Yup.object().shape({});

export interface InvoiceDetailViewModel {
  id: number;
  invoiceID: number;
  productID: number;
  product?: ProductViewModel;
  description: string;
  quantity: number;
  price: number;
  invoiceDetailSalesTaxes: InvoiceDetailSalesTaxViewModel[];
  subTotal: number;
}

export interface InvoiceDetailTableResultViewModel
  extends TableViewModel,
    ResultViewModel {
  data: InvoiceDetailViewModel[];
}

export interface InvoiceDetailListResultViewModel extends ResultViewModel {
  data: InvoiceDetailViewModel[];
}

export interface InvoiceDetailResultViewModel extends ResultViewModel {
  data: InvoiceDetailViewModel;
}

export const invoiceDetailDefaultValue: InvoiceDetailViewModel = {
  id: 0,
  invoiceID: 0,
  productID: 0,
  product: undefined,
  description: '',
  quantity: 0,
  price: 0,
  invoiceDetailSalesTaxes: [],
  subTotal: 0,
};

export const invoiceDetailTableDefaultValue: InvoiceDetailTableResultViewModel =
  {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    isSuccess: true,
    errorMessage: undefined,
  };
