interface Props {
  onClick?: () => void;
  children: string;
}

export const OkButton: React.FC<Props> = ({ onClick, children }) => {
  return (
    <button
      // label={label}
      onClick={onClick}
      type="button"
      className="ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm bg-primary-500  text-white hover:bg-primary-700"
    >
      {children}
    </button>
  );
};
