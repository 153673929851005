import { FieldHookConfig, useField } from 'formik';

type CheckboxProps = {
  label?: string;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & FieldHookConfig<boolean>;

export const FormikCheckbox: React.FC<CheckboxProps> = ({
  label,
  readonly,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  return (
    <div className="w-full">
      <label className="text-sm flex items-center gap-x-2 font-medium text-green-700">
        <input
          type="checkbox"
          readOnly={readonly}
          checked={field.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            helpers.setValue(e.target.checked);
            if (onChange) {
              onChange(e);
            }
          }}
          className={` ${meta.error ? 'text-red-500' : ''}`}
        />
        {label}
      </label>
      {meta.error ? <div className="text-red-500">{meta.error}</div> : null}
    </div>
  );
};
