export const topRoutes = {
  HOME: '/',
  REVENUE_AND_COLLECTIONS: '/revenue-and-collections',
  INVENTORY: '/inventory',
  PURCHASE_AND_PAYMENTS: '/purchase-and-payments',
  ACCOUNTING: '/accounting',
  REPORTS: '/reports',
  SETTINGS: '/settings',
};

export const routes = {
  HOME: '/',
  REVENUE: `${topRoutes.REVENUE_AND_COLLECTIONS}`,
  COLLECTION_SETUP: `${topRoutes.REVENUE_AND_COLLECTIONS}/collection-set-up`,
  CUSTOMER_STATEMENT: `${topRoutes.REVENUE_AND_COLLECTIONS}/customer-statements`,
  RNC_CUSTOMERS: `${topRoutes.REVENUE_AND_COLLECTIONS}/customers`,
  RNC_ESTIMATES: `${topRoutes.REVENUE_AND_COLLECTIONS}/estimates-and-quotes`,
  RNC_INVOICES: `${topRoutes.REVENUE_AND_COLLECTIONS}/invoices`,
  RNC_RECURRING_INVOICES: `${topRoutes.REVENUE_AND_COLLECTIONS}/recurring-invoices`,
  CONTACTS: `${topRoutes.REVENUE_AND_COLLECTIONS}/contacts`,
  CURRENCY: `${topRoutes.REVENUE_AND_COLLECTIONS}/currency`,
  INVENTORIES: `${topRoutes.INVENTORY}`,
  CATEGORIES: `${topRoutes.INVENTORY}/categories`,
  INVENTORY_PRODUCTS: `${topRoutes.INVENTORY}/products-and-services`,
  PURCHASE: `${topRoutes.PURCHASE_AND_PAYMENTS}`,
  BILLS: `${topRoutes.PURCHASE_AND_PAYMENTS}/bills`,
  PAYMENTS: `${topRoutes.PURCHASE_AND_PAYMENTS}/payment-set-up`,
  VENODRS: `${topRoutes.PURCHASE_AND_PAYMENTS}/vendors`,
  PURCHASE_PRODUCTS: `${topRoutes.PURCHASE_AND_PAYMENTS}/products-and-services`,
  ACCOUNTING: `/${topRoutes.ACCOUNTING}`,
  CHARTOFACCOUNTS: `${topRoutes.ACCOUNTING}/chart-of-accounts`,
  TRANSACTIONS: `${topRoutes.ACCOUNTING}/transactions`,
  RECON: `${topRoutes.ACCOUNTING}/recon`,
  BANK_CONNECTIONS: `${topRoutes.ACCOUNTING}/bank-connections`,
  REPORTS: `${topRoutes.REPORTS}`,
  COMPARATIVE: `${topRoutes.REPORTS}/profitandloss/comparative`,
  QUARTER: `${topRoutes.REPORTS}/profitandloss/quarter`,
  YEAR_TO_DATE: `${topRoutes.REPORTS}/profitandloss/year-to-date`,
  MONTHLY: `${topRoutes.REPORTS}/profitandloss/monthly`,
  SETTINGS: `${topRoutes.SETTINGS}`,
  SALES_TAX: `${topRoutes.SETTINGS}/sales-taxes`,
};
