import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelButton, CancelLinkButton, SubmitButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Space } from '@components/Space';
import { currencyDefaultValue, CurrencyValidation } from '@models/Currency';
import { CurrencyService } from '@services/Currency';
import { routes } from 'src/config/routes';

interface Props {
  isModal?: boolean;
  onModalClose?: () => void;
  onFormCancel?: () => void;
}

export const CurrencyCreate: React.FC<Props> = ({
  isModal = false,
  onModalClose,
  onFormCancel,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {isModal && (
        <div className="w-full flex flex-col items-end">
          <span onClick={onModalClose}>x</span>
        </div>
      )}
      <Box size="medium">
        <Formik
          initialValues={currencyDefaultValue}
          validationSchema={CurrencyValidation}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) => {
            CurrencyService.create(values)
              .then((response) => {
                if (response.status == 201) {
                  navigate(`${routes.CURRENCY}`);
                } else {
                  console.log('Error: Failed to create a record.');
                }
              })
              .catch((error) => {
                alert('Error: Failed to handle the request.');
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            return (
              <>
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <FormikInput label="Name" name="name" />
                    <FormikInput label="Shortname" name="shortname" />
                    <FormikInput label="Symbol" name="symbol" />

                    <Space />
                  </FormSectionContainer>
                  <FormButtonsContainer>
                    {isModal && onFormCancel && (
                      <CancelButton onClick={onFormCancel} />
                    )}
                    {!isModal && <CancelLinkButton to={`${routes.CURRENCY}`} />}

                    <SubmitButton
                      label="Save"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    />
                  </FormButtonsContainer>
                </form>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};
