import { Link } from 'react-router-dom';

interface Props {
  to: string;
  children?: React.ReactNode;
}

export const HandleClicker: React.FC<Props> = ({ to, children }) => {
  return <Link to={to}>{children}</Link>;
};
