import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { Box, BoxBodyContainer } from '@components/Box';
import { routes } from '@config/routes';

export const Recon = () => {
  return (
    <Box>
      <BoxBodyContainer>
        <BreadCrumbs
          links={[
            { link: 'Accounting', to: `` },
            { link: 'recon', to: `${routes.RECON}` },
          ]}
        />
      </BoxBodyContainer>
      <Outlet />
    </Box>
  );
};
