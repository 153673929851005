import * as Yup from 'yup';

import { ResultViewModel } from './common/ResultViewModel';
import { TableViewModel } from './common/TableViewModel';

export interface ShippingAddressViewModel {
  id?: number;
  uiid?: number;
  customerId?: number;
  currencyCode: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  isActive?: true;
}

export interface ShippAddressResultViewModel extends ResultViewModel {
  data: ShippingAddressViewModel;
}

export interface ShippingAddressListResultViewModel extends ResultViewModel {
  data: ShippingAddressViewModel[];
}

export interface ShippingTableViewModel extends TableViewModel {
  data: ShippingAddressViewModel[];
}

export const shippingAddressDefaultValue: ShippingAddressViewModel = {
  id: 0,
  uiid: 0,
  customerId: 0,
  currencyCode: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  isActive: true,
};

export const shippingTableDefaultValue: ShippingTableViewModel = {
  data: [],
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
};

export const shippingAddressValidation = Yup.object().shape({
  currencyCode: Yup.string()
    .max(20, 'First Name exceeds the maximum length of 20 characters')
    .nullable(),
  address1: Yup.string()
    .max(100, 'Address1 exceeds the maximum length of 100 characters')
    .required('Address1 is Required'),
  address2: Yup.string()
    .max(50, 'Address2 exceeds the maximum length of 50 characters')
    .nullable(),
  city: Yup.string()
    .max(50, 'City exceeds the maximum length of 50 characters')
    .nullable(),
  state: Yup.string()
    .max(50, 'State exceeds the maximum length of 50 characters')
    .nullable(),
  country: Yup.string()
    .max(50, 'Country exceeds the maximum length of 50 characters')
    .nullable(),
  postalCode: Yup.string()
    .max(50, 'PostalCode exceeds the maximum length of 50 characters')
    .nullable(),
});
