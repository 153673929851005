import { PlusCircleIcon } from '@heroicons/react/20/solid';

interface Props {
  label: string;
  onClick: () => void;
}

export const AddRecord: React.FC<Props> = ({ onClick, label }) => {
  return (
    <>
      <div className="flex items-center">
        <button
          type="button"
          className="bg-primary-500 text-white rounded-md px-2 hover:bg-primary-600 py-1.5 flex space-x-2"
          onClick={onClick}
        >
          <PlusCircleIcon width={24} height={24} />
          <span className="text-500 font-normal">{label}</span>
        </button>
      </div>
    </>
  );
};
