import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

import { PageContainer } from '@components/PageContainer';
import { routes } from '@config/routes';

export const CollectionSetUp = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        links={[
          { link: 'Revenue and Collections', to: `` },
          { link: 'Collection Setup', to: `${routes.COLLECTION_SETUP}` },
        ]}
      />
      {/* change this to main content */}
      <HeaderContainer>This is Collection Setup</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
