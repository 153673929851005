interface Props {
  children?: React.ReactNode;
}

export const Details: React.FC<Props> = ({ children }) => {
  return (
    <div className="px-8">
      <div className="py-8 space-y-8">{children}</div>
    </div>
  );
};
