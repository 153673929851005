import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { CategoryCreate } from './CategoryCreate';

import { AddRecord } from '@components/ActionButtons';
import { BoxHeaderTitle } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { Modal } from '@components/Modal';
import { routes } from '@config/routes';

export const Category: React.FC = () => {
  const [showCreateCategory, setShowCreateCategory] = useState(false);

  const baseBreadCrumbs = [{ link: 'Inventory', to: '' }];

  const isOnCustomersPage = location.pathname === routes.CATEGORIES;

  const breadCrumbLinks = isOnCustomersPage
    ? baseBreadCrumbs
    : [...baseBreadCrumbs, { link: 'Category', to: routes.CATEGORIES }];

  return (
    <>
      <div className="p-4 space-y-4">
        <div className="flex justify-between items-center">
          <div className="text-sm font-semibold text-gray-500">
            Revenue and Collections
          </div>
          <div>
            <BreadCrumbs links={breadCrumbLinks} />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center">
            <BoxHeaderTitle>
              <GridHeaderMenuTitle>
                <div className="text-2xl font-black">Category</div>
              </GridHeaderMenuTitle>
            </BoxHeaderTitle>

            <div className="">
              <div className="flex flex-row space-x-1">
                <AddRecord
                  label="New Category"
                  onClick={() => setShowCreateCategory(true)}
                />
              </div>
              {showCreateCategory && (
                <>
                  <Modal show={true}>
                    <CategoryCreate
                      onClose={() => setShowCreateCategory(false)}
                    />
                  </Modal>
                </>
              )}
            </div>
          </div>

          <div className="p-4 bg-white rounded-b-xl rounded-tr-xl">
            <BoxBodyContainer>
              <Outlet />
            </BoxBodyContainer>
          </div>
        </div>
      </div>

      {/* <Box>
        <BoxBodyContainer>
          <GridHeaderMenuTitle>
            <div className="text-2xl font-black"> Category</div>

            <AddRecord
              label="New Category"
              onClick={() => setShowCreateCategory(true)}
            />
          </GridHeaderMenuTitle>

          <BreadCrumbs
            links={[
              { link: 'Inventory', to: `` },
              { link: 'Category', to: `${routes.CATEGORIES}` },
            ]}
          />
        </BoxBodyContainer>
        {showCreateCategory && (
          <>
            <Modal show={true}>
              <CategoryCreate onClose={() => setShowCreateCategory(false)} />
            </Modal>
          </>
        )}

        <BoxBodyContainer>
          <Outlet />
        </BoxBodyContainer>
      </Box> */}
    </>
  );
};
