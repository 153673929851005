import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelLinkButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { routes } from '@config/routes';
import { ProductDefaultValue, ProductViewModel } from '@models/Product';
import { ProductService } from '@services/Product';

export const ProductDetails: React.FC = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(ProductDefaultValue);
  const navigate = useNavigate();

  const loadData = () => {
    ProductService.getById(Number(id))
      .then((response) => response.data as Promise<ProductViewModel>)
      .then((result) => {
        if (result.id) {
          setProduct(result);
          setIsLoading(false);
          console.log('Success: Fetch the record.');
        } else {
          console.log('Error: Failed to get record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle thr request');
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [id]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <Box size="medium">
          <Formik
            initialValues={product}
            validateOnBlur={true}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
          >
            {() => {
              return (
                <>
                  <form>
                    <FormSectionContainer>
                      <FormikInput label="Name" name="name" />
                      <FormikInput label="Description" name="description" />
                      <FormikInput label="price" name="price" />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <CancelLinkButton to={`${routes.PURCHASE_PRODUCTS}`} />
                      <button
                        className="bg-[#58993C] w-20 ml-4 rounded-md "
                        onClick={() =>
                          navigate(
                            `${routes.PURCHASE_PRODUCTS}/${product.id}/edit`
                          )
                        }
                      >
                        Edit
                      </button>
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
