import { Formik } from 'formik';
import { useEffect, useState } from 'react';

import { CloseButton, DeleteButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelButton, SubmitButton } from '@components/Form';
import { FormikInput } from '@components/Form/FormikInput';
import { Loading } from '@components/Loading';
import { Space } from '@components/Space';
import {
  CurrencyValidation,
  CurrencyViewModel,
  currencyDefaultValue,
} from '@models/Currency';
import { CurrencyService } from '@services/Currency';

interface Props {
  currencyId?: number;
  onClose?: () => void;
  onDelete?: () => void;
}

export const CurrencyEdit: React.FC<Props> = ({
  currencyId,
  onClose,
  onDelete,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currency, setcurrency] = useState(currencyDefaultValue);

  const loadCurrency = () => {
    CurrencyService.getById(Number(currencyId))
      .then((response) => response.data as Promise<CurrencyViewModel>)
      .then((result) => {
        if (result.id) {
          setcurrency(result);
          setIsLoading(false);
          console.log('Success: Fetched the record.');
        } else {
          console.log('Error: Failed to get record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  useEffect(() => {
    loadCurrency();
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <CloseButton onClick={onClose} />

        <Box size="medium">
          <Formik
            initialValues={currency}
            validationSchema={CurrencyValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              CurrencyService.update(Number(currencyId), values)
                .then((response) => {
                  if (response.status == 204) {
                    window.location.reload();
                  } else {
                    console.log('Error: Failed to create a record.');
                    alert('Error: Failed to update record');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <>
                  <form method="POST" onSubmit={formikProps.handleSubmit}>
                    <FormSectionContainer>
                      <FormikInput label="Name" name="name" />
                      <FormikInput label="shortName" name="shortName" />
                      <FormikInput label="symbol" name="symbol" />
                      <Space />
                    </FormSectionContainer>
                    <FormButtonsContainer>
                      <div className="flex space-x-4">
                        <CancelButton onClick={onClose} />

                        <DeleteButton onClick={onDelete} />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </div>
                    </FormButtonsContainer>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
};
