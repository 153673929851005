import axios from 'axios';

import { api } from '@config/apiEndpoints';
import { CustomerSelectOptionResultViewModel } from '@models/Customer';
import {
  RecurringInvoiceTableResultViewModel,
  RecurringInvoiceViewModel,
} from '@models/RecurringInvoice';
import { RecurringInvoiceDetailListResultViewModel } from '@models/RecurringInvoiceDetail';
import { getDataUrl } from '@utils/dataUrls';

export const RecurringInvoiceService = {
  create: async function (recurringInvoice: RecurringInvoiceViewModel) {
    const createUrl = `${api.BASE_URL}${api.RECURRING_INVOICES_ENDPOINT}`;

    console.log(createUrl);
    return axios.post(createUrl, recurringInvoice);
  },

  delete: async function (id: number) {
    const deleteURL = `${api.BASE_URL}${api.RECURRING_INVOICES_ENDPOINT}/${id}`;

    return axios.delete(deleteURL);
  },

  getList: async function (
    page?: number,
    pageSize?: number,
    searchFields?: string,
    search?: string,
    filters?: string
  ) {
    let dataUrl = getDataUrl(
      api.BASE_URL,
      api.RECURRING_INVOICES_ENDPOINT,
      page,
      pageSize,
      searchFields,
      search
    );

    if (filters) {
      dataUrl = dataUrl + filters;
    }

    return axios.get(dataUrl).then((response) => {
      return response.data as Promise<RecurringInvoiceTableResultViewModel>;
    });
  },
  getById: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.RECURRING_INVOICES_ENDPOINT}/${id}`;

    return axios.get(dataUrl);
  },
  end: (id: number, data: { endTime: Date }) => {
    return axios.put(
      `${api.BASE_URL}${api.RECURRING_INVOICES_ENDPOINT}/${id}/end`,
      data
    );
  },
  update: async function (id: number, estimates: RecurringInvoiceViewModel) {
    const updateUrl = `${api.BASE_URL}${api.RECURRING_INVOICES_ENDPOINT}/${id}`;

    return axios.put(updateUrl, estimates);
  },
  duplicate: async function (id: number) {
    const duplicateUrl = `${api.BASE_URL}${api.RECURRING_INVOICES_ENDPOINT}/${id}/duplicate`;

    return axios.post(duplicateUrl);
  },
  getRecurringInvoiceDetails: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.RECURRING_INVOICES_ENDPOINT}/${id}/details`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<RecurringInvoiceDetailListResultViewModel>
      );
  },
  getCustomerFilterSelectOptions: async function () {
    const dataUrl = `${api.BASE_URL}${api.RECURRING_INVOICES_ENDPOINT}/customer-filter-select-options`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<CustomerSelectOptionResultViewModel>
      );
  },
};
