import { useEffect, useState } from 'react';

import { ChartOfAccountCreate } from './ChartOfAccountCreate';
import { ChartOfAccountEdit } from './ChartOfAccountEdit';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { FormButtonsContainer } from '@components/Container';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import {
  ChartOfAccountResultViewModel,
  ChartOfAccountTypeViewModel,
  ChartOfAccountViewModel,
} from '@models/ChartOfAccount';
import { ResultViewModel } from '@models/common/ResultViewModel';
import { ChartOfAccountService } from '@services/ChartofAccount';

const accountTypeGroups = [
  { typeGroup: 'Assets', label: 'Assets', isActive: true },
  {
    typeGroup: 'LiabilitiesAndCreditCards',
    label: 'Liabilities & Credit Cards',
    isActive: false,
  },
  { typeGroup: 'Income', label: 'Income', isActive: false },
  { typeGroup: 'Expenses', label: 'Expenses', isActive: false },
  { typeGroup: 'Equity', label: 'Equity', isActive: false },
];

export const ChartOfAccountList: React.FC = () => {
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showEditChartOfAccount, setShowEditChartOfAccount] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedChartOfAccountId, setSelectedChartOfAccountId] =
    useState<number>(0);
  const [chartOfAccountName, setChartOfAccountName] = useState<string>('');

  const [headerTabs, setHeaderTabs] = useState(accountTypeGroups);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAccountTypeLoading, setIsAccountTypeLoading] =
    useState<boolean>(true);
  const [accountTypes, setAccountTypes] =
    useState<ChartOfAccountTypeViewModel[]>();

  const [isAccountLoading, setIsAccountLoading] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<ChartOfAccountViewModel[]>();

  const handleActiveClick = (tabName: any) => {
    const tabs = headerTabs.map((tab) => {
      if (tab.typeGroup === tabName) {
        return { ...tab, isActive: true };
      } else {
        return { ...tab, isActive: false };
      }
    });
    setHeaderTabs(tabs);
  };

  const loadAccountTypes = () => {
    ChartOfAccountService.getChartOfAccountTypes().then((result) => {
      setAccountTypes(result.data);
      setIsAccountTypeLoading(false);
    });
  };

  const loadAccounts = () => {
    ChartOfAccountService.getList().then((result) => {
      setAccounts(result.data);
      setIsAccountLoading(false);
    });
  };

  useEffect(() => {
    if (!isAccountTypeLoading && !isAccountLoading) {
      setIsLoading(false);
    }
  }, [isAccountTypeLoading, isAccountLoading]);

  useEffect(() => {
    loadAccountTypes();
    loadAccounts();
  }, []);

  const handleEdit = (rowId: any) => {
    setSelectedChartOfAccountId(rowId);
    setShowEditChartOfAccount(true);
  };

  const deleteRecord = (id: number) => {
    ChartOfAccountService.delete(id).then((response) => {
      if (response.status == 204) {
        console.log('Successfully deleted the record.');
        window.location.reload();
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Error: Failed to delete the record. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const handleDelete = async (rowId: any) => {
    try {
      ChartOfAccountService.getById(Number(rowId))
        .then(
          (respone) => respone.data as Promise<ChartOfAccountResultViewModel>
        )
        .then((result) => {
          if (result.isSuccess) {
            setSelectedChartOfAccountId(rowId);
            setChartOfAccountName(result.data.name);
            setShowDelete(true);
            console.log('Success: Fetched the record.');
          } else {
            const message = `Error: Failed to fetch category details for deletion. ${result.errorMessage}`;
            alert(message);
            console.log(message);
          }
        });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <Space />
        <div className="flex justify-center">
          <ul className="flex ml-4 mr-4 bg-[#CDEDDF] rounded-xl ">
            {headerTabs.map((tab, index) => (
              <li
                key={index}
                className={`rounded-md m-2 p-2 hover:bg-[#318760] hover:text-white hover:font-semibold text-sm cursor-pointer ${
                  tab.isActive ? 'bg-[#318760] text-white' : ''
                }`}
                onClick={() => {
                  handleActiveClick(tab.typeGroup);
                }}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>
        <Space />
        {accountTypes &&
          accountTypes
            .filter(
              (o) =>
                o.typeGroup == headerTabs.find((i) => i.isActive)?.typeGroup
            )
            .map((accountType, index) => (
              <div key={index} className="max-w-5xl  mx-auto  ">
                <p className="p-2 w-full bg-[#56C392] font-semibold text-black text-[12px]">
                  {accountType.name}
                </p>
                <table className=" border-collapse border-b border-gray-300 w-full ">
                  <tbody className="text-sm m-2">
                    {accounts &&
                      accounts
                        .filter((account) => account.type == accountType.name)
                        .map((account, index) => (
                          <tr key={index} className="h-[52px] w-full ">
                            <td className="  p-2 w-60 font-semibold text-[12px] ">
                              {account.name}
                            </td>
                            <td className="  w-80 p-2  ">
                              {account.description}
                            </td>
                            <td className=" w-80 p-2   ">
                              <div className="flex justify-end">
                                <svg
                                  onClick={() => handleEdit(account.id)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  height="24"
                                  width="24"
                                  strokeWidth="1.5"
                                  stroke="#318760"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                  />
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  height="24"
                                  width="24"
                                  strokeWidth="1.5"
                                  stroke="#318760"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div
                  className="flex justify-end p-2"
                  onClick={() => setShowCreateAccount(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    strokeWidth="1.5"
                    stroke="#318760"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>

                {/* <button
                  className="m-4 p-2 text-sm border border-green-700 rounded-md hover:bg-[#387D1C] hover:text-white hover:font-semibold cursor-pointer "
                  
                >
                  Add New Account
                </button> */}
              </div>
            ))}
        {showCreateAccount && (
          <>
            <Modal show={true}>
              <ChartOfAccountCreate
                onClose={() => setShowCreateAccount(false)}
              />
            </Modal>
          </>
        )}
        {showEditChartOfAccount && (
          <>
            <Modal show={true}>
              {selectedChartOfAccountId && (
                <ChartOfAccountEdit
                  onClose={() => setShowEditChartOfAccount(false)}
                  onDelete={() => {
                    setShowEditChartOfAccount(false);
                    handleDelete(selectedChartOfAccountId);
                  }}
                  chartOfAccountId={selectedChartOfAccountId}
                />
              )}
            </Modal>
          </>
        )}
        {showDelete && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setShowDelete(false)} />
              <Box size="medium">
                <>
                  <div className="px-4">
                    <span>
                      Are you sure to delete Account: {chartOfAccountName}
                    </span>
                  </div>
                  <FormButtonsContainer>
                    <div className="space-x-2">
                      <CancelButton onClick={() => setShowDelete(false)} />
                      <OkButton
                        onClick={() => deleteRecord(selectedChartOfAccountId)}
                      />
                    </div>
                  </FormButtonsContainer>
                </>
              </Box>
            </Modal>
          </>
        )}
      </>
    );
  }
};
