import axios from 'axios';

import { api } from '@config/apiEndpoints';
import { CustomerSelectOptionResultViewModel } from '@models/Customer';
import {
  EstimateAndQuoteTableResultViewModel,
  EstimateAndQuoteViewModel,
} from '@models/EstimateAndQuote';
import { EstimateAndQuoteDetailListResultViewModel } from '@models/EstimateAndQuoteDetail';
import { getDataUrl } from '@utils/dataUrls';

export const EstimateAndQuoteService = {
  create: async function (estimateAndQuote: EstimateAndQuoteViewModel) {
    const createUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}`;

    console.log(createUrl);
    return axios.post(createUrl, estimateAndQuote);
  },

  delete: async function (id: number) {
    const deleteURL = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/${id}`;

    return axios.delete(deleteURL);
  },

  getList: async function (
    page?: number,
    pageSize?: number,
    searchFields?: string,
    search?: string,
    filters?: string
  ) {
    let dataUrl = getDataUrl(
      api.BASE_URL,
      api.ESTIMATES_ENDPOINT,
      page,
      pageSize,
      searchFields,
      search
    );

    if (filters) {
      dataUrl = dataUrl + filters;
    }

    return axios.get(dataUrl).then((response) => {
      return response.data as Promise<EstimateAndQuoteTableResultViewModel>;
    });
  },
  getById: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/${id}`;

    return axios.get(dataUrl);
  },
  update: async function (id: number, estimates: EstimateAndQuoteViewModel) {
    const updateUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/${id}`;

    return axios.put(updateUrl, estimates);
  },
  approve: async function (id: number) {
    const approveUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/${id}/approve`;

    return axios.post(approveUrl);
  },
  sendEmail: async function (id: number, file?: File) {
    const sendEmailUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/${id}/send-email`;
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    try {
      const response = await axios.post(sendEmailUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Email sent successfully', response);
      return response;
    } catch (error) {
      console.error('Error sending email', error);
      throw error;
    }
  },
  duplicate: async function (id: number) {
    const duplicateUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/${id}/duplicate`;

    return axios.post(duplicateUrl);
  },
  convertToInvoice: async function (id: number) {
    const convertToInvoiceUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/${id}/convert-to-invoice`;

    return axios.post(convertToInvoiceUrl);
  },
  getEstimateDetails: async function (id: number) {
    const dataUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/${id}/details`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<EstimateAndQuoteDetailListResultViewModel>
      );
  },
  getCustomerFilterSelectOptions: async function () {
    const dataUrl = `${api.BASE_URL}${api.ESTIMATES_ENDPOINT}/customer-filter-select-options`;

    return axios
      .get(dataUrl)
      .then(
        (response) =>
          response.data as Promise<CustomerSelectOptionResultViewModel>
      );
  },
};
