interface Iprops {
  children: React.ReactNode;
}

export const Header: React.FC<Iprops> = ({ children }) => {
  return (
    <>
      <header className="bg-[#5BCB99] h-[69px]">
        <div className="  w-full h-full ">{children}</div>
      </header>
    </>
  );
};
