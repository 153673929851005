import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

import { PageContainer } from '@components/PageContainer';
import { routes } from '@config/routes';

export const PaymentsSetup = () => {
  return (
    <PageContainer>
      <BreadCrumbs
        links={[
          {
            link: 'Purchase and Payments',
            to: ``,
          },
          {
            link: 'Payments Setup',
            to: `${routes.PAYMENTS}`,
          },
        ]}
      />
      {/* change this to main content */}
      <HeaderContainer>This is Payment Set Up</HeaderContainer>
      {/* change this to main content */}
    </PageContainer>
  );
};
